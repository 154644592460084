<template>
  <div>
    <div class="card mb-4 shadow-lg">
      <div class="m-2 flex flex-row">
        <div
          :class="[uiState === 'show' ? 'w-full grow' : '', 'mt-2 min-w-60']"
        >
          <h4 class="font-semibold">{{ referral.appointment.animal.name }}</h4>
          <p class="">{{ referral.appointment.animal.animal_type.name }}</p>
        </div>
        <div v-if="uiState !== 'show'" class="mt-2 w-full grow">
          <base-label> Referred for: </base-label>
          <p class="flex flex-col space-y-1 text-gray-700">
            {{ trimText(referral.reason) }}
          </p>
        </div>
        <div class="mt-2 flex-none">
          <p class="font-semibold">{{ formatDate(referral.created_at) }}</p>
        </div>
      </div>
      <div
        :class="[uiState === 'show' ? 'border-b' : '']"
        class="cursor-pointer bg-beigeLighter"
        @click="toggleShowMore()"
      >
        <fv-icon
          v-if="uiState !== 'show'"
          class="svg-fill inline-block text-blue-700"
          icon="small-chevron-down"
          size="xl"
        />
        <fv-icon
          v-if="uiState === 'show'"
          class="svg-fill inline-block text-blue-700"
          icon="small-chevron-up"
          size="xl"
        />
      </div>
      <div v-if="uiState === 'show'" class="">
        <div class="m-2 mt-6">
          <div class="mb-2 flex flex-col">
            <base-label> Referred for: </base-label>
            <p class="flex flex-col space-y-1 text-gray-700">
              {{ referral.reason }}
            </p>
          </div>
          <div class="mb-2 flex flex-col">
            <base-label> Assessment: </base-label>
            <p class="flex flex-col space-y-1 text-gray-700">
              {{ referral.clinical_diagnosis }}
            </p>
          </div>
          <div class="mb-2 flex flex-col">
            <base-label> Further Contact: </base-label>
            <p class="flex flex-col space-y-1 text-gray-700">
              {{ referral.contact }}
            </p>
          </div>
          <div class="mb-2 flex flex-col">
            <base-label> Follow-up: </base-label>
            <p class="flex flex-col space-y-1 text-gray-700">
              {{ referral.ref_followup }}
            </p>
          </div>
          <div class="mb-4 rounded bg-blue-100 p-4 text-blue-700">
            <base-label class="text-blue-700">
              <fv-icon
                class="svg-fill inline-block text-blue-700"
                icon="information"
                size="lg"
              />
              Referral instruction
            </base-label>
            <p class="mt-4 flex flex-col space-y-1 font-medium">
              {{ referral.referral_instruction }}
            </p>
          </div>

          <div v-if="referral.followups.length" class="mt-4">
            <base-label>Referral follow-up</base-label>
            <div v-for="followup in referral.followups" :key="followup.id">
              <div
                class="mb-2 flex flex-col rounded border border-alt-lightest p-3"
              >
                <p class="flex flex-col space-y-1">{{ followup.message }}</p>
                <div class="mt-5">
                  <p class="flex flex-col space-y-1 font-semibold">
                    {{ followup.author.display_name }}
                  </p>
                  <p class="mt-1 flex flex-col space-y-1 text-gray-700">
                    {{ formatDate(followup.created_at) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-between bg-gray-100 p-2 pt-5 pb-5">
          <div class="">
            <p class="">
              Meeting
              <text-button
                :to="{
                  name: 'appointment',
                  params: { id: referral.appointment.id },
                }"
              >
                <span class="font-semibold text-blue-700 hover:text-blue-600">
                  #{{ referral.appointment.id }}
                </span>
              </text-button>
            </p>
            <p class="">
              Veterinarian
              <text-button :to="{ name: 'statistics' }">
                <span class="font-semibold text-blue-700 hover:text-blue-600">
                  {{ referral.appointment.booking.user.display_name }}
                </span>
              </text-button>
            </p>
          </div>
          <div class="">
            <base-button
              class="w-full"
              color="primary"
              @click="showModal = true"
              >Submit follow-up</base-button
            >
          </div>
        </div>
      </div>
    </div>
    <ClinicFollowUpModal
      :show-modal="showModal"
      :ref-id="referral.id"
      @toggle="toggleModal"
      @addFollowup="addFollowup"
    ></ClinicFollowUpModal>
  </div>
</template>

<script>
import moment from 'moment';
import ClinicFollowUpModal from '@/components/clinic-map/clinic/ClinicFollowUpModal';

export default {
  components: {
    ClinicFollowUpModal,
  },
  props: {
    referral: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiResponse: {
        data: [],
      },
      showModal: false,
      nextPageUrl: null,
      previousPageUrl: null,
      followups: this.referral.followups,
      referrals: [],
      loading: false,
      uiState: 'hide',
    };
  },
  methods: {
    addFollowup(appointmentReferralFollowup) {
      this.followups.unshift(appointmentReferralFollowup);
    },
    trimText(text, length = 80) {
      const index = text.indexOf(' ', length);
      return index === -1 ? text : text.substring(0, index) + '...';
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleShowMore() {
      this.uiState = this.uiState == 'show' ? 'hide' : 'show';
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
  },
};
</script>

<style scoped></style>
