var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col space-y-4" },
    [
      _c(
        "h4",
        {
          staticClass:
            "block text-primary-darker text-lg font-semibold border-t border-gray-400 pt-3 mt-3",
        },
        [_vm._v(" Animal types ")]
      ),
      _c(
        "desc-list",
        [
          _c(
            "desc-item",
            { staticClass: "border-none" },
            [
              _c("desc-title", [_vm._v(" Currently accepted ")]),
              _c("desc-data", [
                _c(
                  "ul",
                  { staticClass: "flex flex-col space-y-1" },
                  _vm._l(_vm.list, function (animalType) {
                    return _c(
                      "li",
                      {
                        key: animalType.id,
                        staticClass: "flex justify-between ",
                      },
                      [
                        _c("span", {}, [
                          _vm._v(
                            _vm._s(
                              animalType.name ||
                                _vm.getAnimalTypeName(animalType.id)
                            )
                          ),
                        ]),
                        _c(
                          "base-button",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              size: "sm",
                              type: "button",
                              color: "transparent",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.remove(animalType.id)
                              },
                            },
                          },
                          [
                            _c("fv-icon", {
                              staticClass: "h-4 w-4 text-red-600",
                              attrs: { icon: "close" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _c(
            "desc-item",
            { staticClass: "border-none" },
            [
              _c("desc-title", [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("add_animal_type")) + "s "),
                ]),
              ]),
              _c("desc-data", { staticClass: "mt-1 w-75" }, [
                _c(
                  "form",
                  {
                    staticClass: "flex flex-col space-y-1",
                    attrs: { action: "post" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.errorMsg
                      ? _c("p", { staticClass: "text-red-600" }, [
                          _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
                        ])
                      : _vm._e(),
                    _c("v-select", {
                      attrs: {
                        name: "animalType",
                        label: "name",
                        placeholder: _vm.$t("choose_type_of_animal"),
                        transition: "none",
                        multiple: "",
                        "close-on-select": false,
                        options: _vm.filterList,
                        disabled: _vm.loading,
                      },
                      on: { input: _vm.dispatchInput },
                      model: {
                        value: _vm.animalTypesToAdd,
                        callback: function ($$v) {
                          _vm.animalTypesToAdd = $$v
                        },
                        expression: "animalTypesToAdd",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "base-button",
                          {
                            attrs: {
                              loading: _vm.loading,
                              type: "submit",
                              size: "lg",
                              color: "primary",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }