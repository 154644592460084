var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex justify-between items-center" },
    [
      _c(
        "base-label",
        { staticClass: "font-semibold", attrs: { for: _vm.title } },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c(
        "div",
        _vm._l(_vm.buttons, function (button) {
          return _c(
            "button",
            {
              key: button.id + "coordinates",
              staticClass: "w-40 py-3 border-b-2",
              class:
                _vm.filterValue === button.value
                  ? "border-primary-darker text-primary-darker"
                  : "hover:border-primary-darker hover:text-primary-darker border-gray-300 text-gray-300",
              attrs: {
                value: _vm.filterValue,
                name: _vm.title,
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("selected", button.value)
                },
              },
            },
            [_vm._v(" " + _vm._s(button.label) + " ")]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }