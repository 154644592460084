<template>
  <form class="w-full" @submit.prevent="search">
    <div class="flex items-end justify-between space-x-3">
      <div class="flex flex-1 flex-col space-y-2">
        <base-label for="pet"> Pet </base-label>
        <base-input
          id="pet"
          ref="petSearch"
          v-model="animalName"
          placeholder="Search for name or ID"
        />
      </div>
      <div class="flex flex-1 flex-col space-y-2">
        <base-label for="owner"> Owner </base-label>
        <base-input
          id="owner"
          v-model="animalOwnerName"
          placeholder="Search for name or ID"
        />
      </div>
      <div class="flex-1">
        <base-select
          class="w-full text-sm"
          label-attribute="name"
          value-attribute="id"
          :options="animalTypes"
          placeholder="Animal type"
          :value="animalTypeId"
          @change="animalTypeId = $event"
        />
      </div>

      <div class="inline-flex space-x-2">
        <base-button :loading="loading" color="primary" type="submit">
          <font-awesome-icon icon="search" />
          <span class="ml-2 inline-flex">Search</span>
        </base-button>
        <base-button
          v-if="searched"
          color="cancel"
          type="button"
          @click="clearSearch"
        >
          <fv-icon icon="close" class="h-4 w-4" />
          <span class="ml-2 inline-flex">Clear</span>
        </base-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searched: false,
      animalName: '',
      animalOwnerName: '',
      animalTypeId: '',
    };
  },
  computed: {
    ...mapGetters('animal', {
      animalTypes: 'getAnimalTypes',
    }),
  },
  methods: {
    search() {
      this.searched = !!(
        this.animalName ||
        this.animalOwnerName ||
        this.animalTypeId
      );

      this.$emit('search', {
        animalName: this.animalName,
        animalOwnerName: this.animalOwnerName,
        animalTypeId: this.animalTypeId,
      });
    },
    clearSearch() {
      this.searched = false;
      this.animalName = '';
      this.animalOwnerName = '';
      this.animalTypeId = '';

      this.$emit('search', {
        animalName: this.animalName,
        animalOwnerName: this.animalOwnerName,
        animalTypeId: this.animalTypeId,
      });
    },
  },
};
</script>

<style scoped></style>
