var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-1 flex flex-col space-y-4" }, [
    _vm.error
      ? _c("p", { staticClass: "text-red-700" }, [
          _vm._v(" " + _vm._s(_vm.error) + " "),
        ])
      : _vm._e(),
    _c("div", { staticClass: "flex justify-between space-x-4" }, [
      _c(
        "div",
        { staticClass: "flex-1 relative" },
        [
          _c("range-slider", {
            attrs: {
              width: "100%",
              min: 0,
              max: 100,
              interval: 5,
              tooltip: "always",
              disabled: _vm.uiState === "updating",
              clickable: false,
              "rail-style": {
                color: "#00bed6",
              },
            },
            on: { "drag-end": _vm.setRating },
            model: {
              value: _vm.sliderValue,
              callback: function ($$v) {
                _vm.sliderValue = $$v
              },
              expression: "sliderValue",
            },
          }),
          _c("spinner-overlay", {
            attrs: { loading: _vm.uiState === "updating" },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "text-button",
            { attrs: { size: "sm" }, on: { click: _vm.resetToDefaultValue } },
            [_vm._v(" Reset to default ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }