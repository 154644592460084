var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.error
      ? _c("span", { staticClass: "text-red-600" }, [_vm._v(_vm._s(_vm.error))])
      : _c(
          "form",
          {
            staticClass: "mt-1",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitEditClinic.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "inline-flex items-center" }, [
              _c(
                "label",
                {
                  staticClass: "inline-flex items-center space-x-1",
                  attrs: { for: "active" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.clinicClone[_vm.property],
                        expression: "clinicClone[property]",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      disabled: _vm.loading,
                      name: "active",
                      "aria-label": "Active",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.clinicClone[_vm.property])
                        ? _vm._i(_vm.clinicClone[_vm.property], null) > -1
                        : _vm.clinicClone[_vm.property],
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.clinicClone[_vm.property],
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.clinicClone,
                                  _vm.property,
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.clinicClone,
                                  _vm.property,
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.clinicClone, _vm.property, $$c)
                          }
                        },
                        _vm.submitEditClinic,
                      ],
                    },
                  }),
                  _c("span", {}, [
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm.clinicClone[_vm.property]
                            ? _vm.$t("Active*")
                            : _vm.$t("Activate*")
                        )
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }