<template>
  <v-select
    v-model="clinicClone[property]"
    :options="options"
    label="name"
    :name="name"
    class="form-control w-50"
    :disabled="loading"
    :reduce="opt => opt.id"
    @input="submit"
  >
    <!-- <option
      v-for="option in optionValues"
      :key="option.id"
      :value="option.id"
    >
      {{ option.name }}
    </option> -->
  </v-select>
</template>

<script>
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: '',
      clinicClone: cloneDeep(this.clinic),
    };
  },
  computed: {
    optionValues() {
      return [
        {
          id: null,
          name: `--- ${this.$t('value_missing')} ---`,
        },
        ...this.options,
      ];
    },
  },
  methods: {
    ...mapActions('clinicStore', [
      'updateClinic',
      // 'fetchClinicTypes',
    ]),
    async submit() {
      this.error = '';
      this.loading = true;
      try {
        await this.updateClinic({
          id: this.clinic.id,
          [this.property]: this.clinicClone[this.property],
        });
        // this.fetchClinicTypes({ country_id: this.clinicClone.country_id });
        this.loading = false;
      } catch (error) {
        this.error = error.message;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
