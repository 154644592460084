<template>
  <desc-item class="">
    <desc-title>
      <base-button
        size="sm"
        :disabled="loading || !address"
        @click="geoCode"
      >
        <font-awesome-icon
          v-if="loading"
          icon="spinner"
          spin
        />
        <span v-else>
          {{ $t('fetch_address_info') }}
        </span>
      </base-button>
    </desc-title>
    <desc-data>
      <div
        v-if="responseAddress"
        class="mt-1 flex flex-col space-y-2"
      >
        <div class="bg-azure-25 py-4 px-4 rounded flex flex-col space-y-3">
          <div class="flex flex-col space-y-1">
            <p>{{ $t('address') }}</p> <p class="font-semibold">
              {{ responseAddress }}
            </p>
          </div>
          <div
            v-if="lat"
            class="flex flex-col space-y-1"
          >
            <p>{{ $t('latitude') }}:</p> <p class="font-semibold">
              {{ lat }}
            </p>
          </div>
          <div
            v-if="lng"
            class="flex flex-col space-y-1"
          >
            <p>{{ $t('longitude') }}:</p> <p class="font-semibold">
              {{ lng }}
            </p>
          </div>
        </div>
        <div class="mt-8 flex flex-col space-y-2">
          <small>{{ $t('is_information_correct') }}</small>
          <base-button
            size="sm"
            color="primary"
            class=""
            @click="submitCoordinates"
          >
            {{ $t('save_coordinates') }}
          </base-button>
          <base-button
            size="sm"
            color="secondary"
            @click="offsetCoordinates"
          >
            {{ $t('save_and_move_cursor_sideways') }}
          </base-button>
          <base-button
            size="sm"
            color="cancel"
            @click="reset"
          >
            {{ $t('cancel') }}
          </base-button>
        </div>
      </div>
      <p
        v-if="error || !address"
        class="text-red-600"
      >
        {{ error || $t('address_missing') }}
      </p>
    </desc-data>
  </desc-item>
</template>

<script>
import { mapActions } from 'vuex';
import gmapsInit from '@/utils/map/gmaps';

export default {
  props: {
    address: {
      type: String,
      default: '',
    },
    clinicId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      geoCoderService: null,
      google: null,
      lat: 0,
      lng: 0,
      responseAddress: '',
      error: '',
      loading: false,
    };
  },

  async mounted() {
    const googleMapApi = await gmapsInit();
    this.google = googleMapApi;
  },

  methods: {
    ...mapActions('clinicStore', ['updateClinic']),
    reset() {
      this.responseAddress = '';
      this.lng = 0;
      this.lat = 0;
      this.error = '';
    },
    async geoCode() {
      this.reset();
      this.loading = true;
      this.geocoderService = new this.google.maps.Geocoder();
      await this.geocoderService.geocode(
        { address: this.address },
        (geocoded, status) => {
          if (status === 'OK') {
            this.responseAddress = geocoded[0].formatted_address;
            this.lat = geocoded[0].geometry.location.lat();
            this.lng = geocoded[0].geometry.location.lng();
          } else if (status === 'ZERO_RESULTS') {
            this.error = this.$i18n.tc('could_not_find_any_address_info');
          } else {
            this.error = this.$i18n.tc('something_went_wrong');
          }
          this.loading = false;
        }
      );
    },
    async submitCoordinates() {
      this.error = '';
      if (this.lat && this.lng && this.clinicId) {
        try {
          const payload = {
            id: this.clinicId,
            lat: this.lat,
            lng: this.lng,
          };
          await this.updateClinic(payload);
          this.$emit('coordinates-persisted', { lat: this.lat, lng: this.lng });
          this.reset();
        } catch (error) {
          console.error(error);
          this.reset();
          this.error = error.message;
        }
      }
    },
    offsetCoordinates() {
      this.lat += 0.00015;
      this.lng += 0.00015;
      this.submitCoordinates();
      // 	0.00001
    },
  },
};
</script>

<style lang="scss">
.text-wrap {
  white-space: normal;
}
</style>
