<template>
  <div class="flex-1 flex flex-col space-y-3">
    <div class="flex justify-between items-center mt-2 flex-wrap">
      <form
        class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4 lg:items-center"
        @submit.prevent="getClinics()"
      >
        <!-- <div class="min-w-60 h-full">
          <v-select
            placeholder="Filter by clinic type"
            class="v-input"
            :options="clinicTypes"
            label="name"
            :reduce="opt => opt.id"
            :value="clinicTypeId"
            @input="clinicTypeId = $event, getClinics()"
          />
        </div>
        <div class="min-w-40 h-full">
          <v-select
            placeholder="Animal type"
            class="v-input"
            :options="animalTypeOptions"
            label="name"
            :reduce="opt => opt.id"
            :value="animalTypeId"
            @input="animalTypeId = $event, getClinics()"
          />
        </div> -->
        <div class="inline-flex space-x-1 items-center">
          <base-input
            id="search"
            v-model="search"
            name="search"
            placeholder="Search"
            class=" min-w-80"
          />
          <base-button color="dark">
            <fv-icon
              icon="search"
            />
          </base-button>
          <base-button
            v-if="filtersAreSet"
            color="danger"
            @click="resetFilters()"
          >
            <fv-icon
              icon="close"
            />
          </base-button>
        </div>
        <div class="border-left">
          <text-button
            v-tooltip="'Show filters'"
            type="button"
            class="border-l border-gray-700"
            @click="showClinicFilterModal = true"
          >
            <fv-icon
              class="text-black mx-1"
              icon="filter"
            />
            Filter
          </text-button>
        </div>
      </form>
      <div class="inline-flex space-x-3 items-center">
        <text-button
          class=""
          :to="{name: 'clinic-map'}"
        >
          <div class="inline-flex items-center space-x-2 text-primary-darker hover:text-primary-darkest">
            <fv-icon
              class="text-sm"
              icon="google-maps"
            /> 
            <span class="0">Go to map</span>
          </div>
        </text-button>
        <!-- Editing directly from the list is disabled until we can hydrate the data in the list after edit -->

        <base-button
          @click="showCreateClinicModal = true"
        >
          <fv-icon
            icon="add"
            class="text-gray-600"
          /><span class="ml-2">Add a clinic</span>
        </base-button>
      </div>
    </div>
    <div
      v-if="Object.keys(filter).length !== 0"
      class="flex space-x-2"
    >
      <span
        v-for="(value, key, index) in filter"
        :key="index"
        class="bg-primary-darker text-white rounded-lg py-1 px-3 flex items-center"
      >
        {{ !value ? 'missing ' : '' }}
        {{ key.toLowerCase().replace('set', '') }}
        {{ value ? 'added ' : '' }}
        <button
          type="button"
          @click="removeFilter(key)"
        >
          <fv-icon
            class="ml-2"
            icon="close"
            size="xs"
          />
        </button>
      </span>
    </div>
    <div class="flex-1 relative">
      <table-container :theads="theads">
        <tr
          v-for="clinic in clinics"
          :key="clinic.id"
          class="text-left text-sm hover:bg-gray-100"
          :class="{'bg-red-100' : !clinic.active}"
        >
          <table-data>
            <div class="flex justify-between">
              <router-link
                :to="{name: 'clinic', params: {id: clinic.id}}"
                class="font-semibold hover:text-primary-darkest"
              >
                <span>{{ clinic.name }}</span>
              </router-link>
              <span class="text-gray-700 inline-flex space-x-1">
                <fv-icon
                  v-if="clinic.lat && clinic.lng"
                  v-tooltip="'This clinic is visible on the map'"
                  icon="map-pin"
                />
                <fv-icon
                  v-if="clinic.animal_types.length"
                  v-tooltip="'This clinic has at least 1 animal type added'"
                  icon="pet"
                />
                <fv-icon
                  v-if="clinic.referral_email || clinic.email"
                  v-tooltip="'This clinic has an email and/or referral email set'"
                  icon="email"
                />
              </span>
            </div>
          </table-data>
          <table-data>
            <span
              v-if="clinic.clinic_type"
              class="rounded-full border bg-gray-100 py-1 px-2 text-xs"
            >
              {{ clinic.clinic_type && clinic.clinic_type.name }}
            </span>
            <span v-else>-</span>
          </table-data>
          <table-data><span>{{ clinic.email }}</span></table-data>
          <table-data>
            <span class="text-gray-700">
              {{ clinic.city && `${clinic.city},` }} {{ clinic.municipality }}
            </span>
          </table-data>
          <table-data />
        </tr>
         
        <div
          slot="tableFooter"
          class="flex w-full justify-between items-baseline"
        >
          <spinner-overlay
            v-if="uiState === 'loading'"
            :loading="uiState === 'loading'"
            size="xxl"
          />
          <div class="bg-gray-100 inline-flex space-x-4">
            <pagination-buttons
              v-if="clinicsList.prev_page_url || clinicsList.next_page_url"
              :pagination-list="clinicsList"
              :loading="uiState === 'loading'"
              @previous="getClinics(clinicsList.prev_page_url)"
              @next="getClinics(clinicsList.next_page_url)"
            />
            <v-select
              v-model="paginate"
              class="v-input"
              :append-to-body="true"
              position="top"
              :clearable="false"
              :searchable="false"
              label="name"
              :reduce="opt => opt.id"
              :options="paginationOptions"
              @input="getClinics()"
            />
          </div>
          <div>Viewing {{ clinics.length }} of {{ clinicsList.total }} clinics</div>
        </div>
      </table-container>
    </div>
    <!-- Editing directly from the list is disabled until we can hydrate the data in the list after edit -->
    <base-modal
      v-if="editClinic"
      :show="showEditForm"
      @close="handleCloseEdit"
    >
      <clinic-edit-card
        :show-borders="false"
        :clinic="editClinic"
      />
    </base-modal>
    <clinic-create-modal
      v-if="showCreateClinicModal"
      :show="showCreateClinicModal"
      @close="showCreateClinicModal = false"
      @add-clinic="addClinic"
    />
    <clinic-filter-modal
      v-if="showClinicFilterModal"
      :show="showClinicFilterModal"
      @close="showClinicFilterModal = false"
      @handle-submit="getClinics(null, $event)"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import PaginationButtons from '@/UI/PaginationButtons.vue';
import ClinicEditCard from '@/components/clinic-map/clinic/ClinicEditCard.vue';
import ClinicCreateModal from '@/components/clinic-map/clinic/ClinicCreateModal.vue';
import ClinicFilterModal from '@/components/clinic-map/clinic/ClinicFilterModal.vue';
import { ClinicsApi } from '@/api';

export default {
  components: {
    PaginationButtons,
    ClinicEditCard,
    ClinicCreateModal,
    ClinicFilterModal,
  },
  data() {
    return {
      clinicsList: [],
      uiState: 'idle',
      search: '',
      theads: ['Name', 'Type', 'Email', 'Location', ''],
      animalTypeId: null,
      clinicTypeId: null,
      showEditForm: false,
      editClinic: null,
      addNewClinic: false,
      paginate: 20,
      paginationOptions: [
        { id: 5, name: '5' },
        { id: 12, name: '12' },
        { id: 20, name: '20' },
        { id: 30, name: '30' },
        { id: 50, name: 50 },
        { id: 100, name: 100 },
      ],
      currentUrl: null,
      showCreateClinicModal: false,
      showClinicFilterModal: false,
    };
  },
  computed: {
    ...mapState('clinicStore', ['animalTypeOptions', 'clinicTypes', 'filter']),
    ...mapState('admin', ['countryId']),
    filtersAreSet() {
      return this.animalTypeId || this.search || this.clinicTypeId;
    },
    clinics() {
      return this.clinicsList?.data || [];
    },
  },
  async mounted() {
    await Promise.all([
      this.getClinics(),
      this.fetchAnimalTypes(),
      this.fetchServiceOptions(),
      this.fetchStationaryCareOptions(),
      this.fetchServiceCategories(),
      this.fetchServiceAnimalGroups(),
      this.fetchEmergencyCareOptions(),
      this.fetchAvailableDays(),
      this.fetchClinicTypes({ country_id: this.countryId }),
    ]);
  },
  methods: {
    ...mapActions('clinicStore', [
      'fetchClinics',
      'fetchAnimalTypes',
      'fetchServiceOptions',
      'fetchStationaryCareOptions',
      'fetchServiceCategories',
      'fetchServiceAnimalGroups',
      'fetchEmergencyCareOptions',
      'fetchAvailableDays',
      'fetchClinicTypes',
    ]),
    ...mapMutations('clinicStore', ['setFilter']),
    async getClinics(url = null) {
      this.uiState = 'loading';
      let clinics = {};
      if (url) {
        this.currentUrl = url;
        const { data } = await this.$axios.get(`${url}`);
        clinics = data.clinics;
      } else {
        const params = this.createRequestParams();
        clinics = await this.fetchClinics(params);
      }
      this.uiState = 'idle';
      this.clinicsList = clinics;
    },
    createRequestParams() {
      const params = {
        country: this.countryId,
        paginate: this.paginate,
        search: this.search,
        ratings: 1,
        // animalTypes: [],
        // clinicTypes: [],
        ...this.filter,
      };
      // if (this.animalTypeId) {
      //   params.animalTypes.push(this.animalTypeId);
      // }
      // if (this.clinicTypeId) {
      //   params.clinicTypes.push(this.clinicTypeId);
      // }
      return params;
    },
    removeFilter(filter) {
      const filterCopy = { ...this.filter };
      delete filterCopy[filter];
      this.setFilter(filterCopy);
      this.getClinics();
    },
    resetFilters() {
      this.search = '';
      this.animalTypeId = null;
      this.clinicTypeId = null;
      this.getClinics();
    },
    toggleEdit(clinic) {
      this.showEditForm = true;
      this.editClinic = clinic;
    },
    handleCloseEdit() {
      this.showEditForm = false;
      this.editClinic = null;
      console.log('Closed edit, hydrating data..');
      // await this.getClinics(this.currentUrl ?? null);
    },
    async addClinic(clinicId) {
      const { data: clinic } = await ClinicsApi.getClinic(clinicId);
      this.clinicsList.data.unshift(clinic);
    },
  },
};
</script>

<style lang="postcss">
.v-input .vs__search::placeholder,
.v-input .vs__dropdown-toggle,
.v-input .vs__dropdown-menu {
  @apply bg-white rounded border border-gray-300 text-gray-800 w-full;
}

.v-input .vs__clear,
.v-input .vs__open-indicator {
  fill: #6a6c77;
}
</style>
