<template>
  <div class="flex flex-col space-y-4">
    <h4 class="block text-primary-darker text-lg font-semibold border-t border-gray-400 pt-3 mt-3">
      Animal types
    </h4>
    <desc-list>
      <desc-item class="border-none">
        <desc-title>
          Currently accepted
        </desc-title>
        <desc-data>
          <ul class="flex flex-col space-y-1">
            <li
              v-for="animalType in list"
              :key="animalType.id"
              class="flex justify-between "
            >
              <span class="">{{ animalType.name || getAnimalTypeName(animalType.id) }}</span>
              <base-button
                size="sm"
                type="button"
                color="transparent"
                class="ml-1"
                @click="remove(animalType.id)"
              >
                <fv-icon
                  class="h-4 w-4 text-red-600"
                  icon="close"
                />
              </base-button>
            </li>
          </ul>
        </desc-data>
      </desc-item>

      <desc-item class="border-none">
        <desc-title>
          <p>
            {{ $t('add_animal_type') }}s
          </p>
        </desc-title>
        <desc-data class="mt-1 w-75">
          <form
            action="post"
            class="flex flex-col space-y-1"
            @submit.prevent="submit"
          >
            <p
              v-if="errorMsg"
              class="text-red-600"
            >
              {{ errorMsg }}
            </p>
            <v-select
              v-model="animalTypesToAdd"
              name="animalType"
              label="name"
              :placeholder="$t('choose_type_of_animal')"
              transition="none"
              multiple
              :close-on-select="false"
              :options="filterList"
              :disabled="loading"
              @input="dispatchInput"
            />
            <div class="text-right">
              <base-button
                :loading="loading"
                type="submit"
                class=""
                size="lg"
                color="primary"
              >
                {{ $t('add') }}
              </base-button>
            </div>
          </form>
        </desc-data>
      </desc-item>
    </desc-list>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import vSelect from 'vue-select';
import { cloneDeep } from 'lodash';

export default {
  components: {
    vSelect,
  },
  props: {
    clinicId: {
      type: [Number, String],
      default: null,
    },
    animalTypeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      list: cloneDeep(this.animalTypeList),
      loading: false,
      errorMsg: '',
      animalTypesToAdd: [],
    };
  },
  computed: {
    ...mapState('clinicStore', ['animalTypeOptions']),

    filterList() {
      return this.animalTypeOptions.filter(({ id }) => {
        const isActive = this.list.some(type => type.id === id);
        const isSelected = this.animalTypesToAdd.some(type => type.id === id);
        return !isActive && !isSelected;
      });
    },
  },
  methods: {
    ...mapActions('clinicStore', ['addAnimalTypes', 'removeAnimalType']),
    getAnimalTypeName(id) {
      const option = this.animalTypeOptions.find(op => op.id === id);
      return option ? option.name : id;
    },
    async submit() {
      try {
        this.loading = true;
        const animalTypeIds = this.animalTypesToAdd.map(type => type.id);
        const { data } = await this.addAnimalTypes({
          animalTypeIds,
          clinic: this.clinicId,
        });
        if (data) {
          this.list = [...this.list, ...this.animalTypesToAdd];
          this.list.sort((a, b) => {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
          this.animalTypesToAdd = [];
          this.loading = false;
          this.$emit('edited', this.list);
        }
      } catch (error) {
        console.error('Error caught', error);
        this.errorMsg = error.message;
        this.loading = false;
      }
    },
    async remove(animalTypeId) {
      const hasRelationShip = this.list.find(
        animalType => animalTypeId === animalType.id
      );
      if (!hasRelationShip) {
        return;
      }
      try {
        this.loading = true;
        const { data } = await this.removeAnimalType({
          animalType: animalTypeId,
          clinic: this.clinicId,
        });
        if (data) {
          console.log('Detahed animal type', data);
          this.list = this.list.filter(type => type.id !== data.type.id);
          this.loading = false;
          this.$emit('edited', this.list);
        }
      } catch (error) {
        console.error('Error caught', error);
        this.errorMsg = error.message;
        this.loading = false;
      }
    },
    dispatchInput(animalTypes) {
      this.animalTypesToAdd = animalTypes;
    },
  },
};
</script>
