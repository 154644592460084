<template>
  <div class="w-full">
    <h4
      class="mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker"
    >
      {{ $t('opening_hours') }}
    </h4>
    <ValidationObserver ref="observer" tag="form" @submit.prevent="submit">
      <form action="post" class="w-full" @submit.prevent="submit">
        <table class="min-w-full">
          <!-- <thead>
            <tr>
              <th />
              <th>
                <label class="text-gray-600"><small>{{ $t('opens') }}</small></label>
              </th>
              <th>
                <label class="text-gray-600"><small>{{ $t('closes') }}</small></label>
              </th>
              <th>
                <label class="text-gray-600"><small>{{ $t('closed') }}</small></label>
              </th>
            </tr>
          </thead> -->
          <tbody>
            <tr
              v-for="(hours, index) in openingHoursCopy"
              :key="hours.weekday"
              class=""
            >
              <div v-if="index === 0" class="mb-2 flex text-center">
                <td class="w-1/6" />
                <td class="w-1/3">
                  <label class="text-gray-600"
                    ><small>{{ $t('opens') }}</small></label
                  >
                </td>
                <td class="w-1/3">
                  <label class="text-gray-600"
                    ><small>{{ $t('closes') }}</small></label
                  >
                </td>
                <td class="w-1/5">
                  <label class="text-gray-600"
                    ><small>{{ $t('closed') }}</small></label
                  >
                </td>
              </div>
              <ClinicOpeningHoursRow
                :opening-hours="hours"
                :hour-options="hourOptions"
                :day-name="dayOptions[hours.weekday].key"
                @value-changed="changeHours"
              />
            </tr>
            <tr class="text-right">
              <base-button
                size="lg"
                color="primary"
                :disabled="loading"
                class="mt-3"
                type="submit"
                :loading="loading"
              >
                {{
                  !isSaved
                    ? $t('save_opening_hours')
                    : $t('opening_hours_saved')
                }}
                <fv-icon v-if="isSaved" class="ml-2" icon="check-mark" />
              </base-button>
            </tr>
            <tr>
              <div
                v-if="displayError"
                class="mb-1 mt-4 flex items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900"
              >
                <span><font-awesome-icon icon="exclamation-circle" /></span>
                <span>{{ errorMessage }}</span>
              </div>
            </tr>
            <tr>
              <dt class="mt-4">
                {{ $t('other_opening_hours') }}
              </dt>
              <ClinicDataEditor
                html-element="textarea"
                :clinic="clinic"
                property="open"
                :placeholder="$t('other_opening_hours_placeholder')"
                :required="false"
              />
            </tr>
          </tbody>
        </table>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClinicOpeningHoursRow from './ClinicOpeningHoursRow.vue';
import ClinicDataEditor from './form/ClinicDataEditor.vue';

export default {
  components: { ClinicOpeningHoursRow, ClinicDataEditor },
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    openingHours: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      updatedHours: [],
      displayError: false,
      errorMessage: '',
      isSaved: false,
    };
  },
  computed: {
    ...mapState('clinicStore', ['dayOptions', 'hourOptions']),
    openingHoursCopy() {
      const hours = this.openingHours.reduce(
        (acc, oh) => ({
          ...acc,
          [oh.weekday]: oh,
        }),
        {}
      );
      const oh = this.dayOptions.map((day, index) => {
        const d = hours[day.id] || {};
        return {
          weekday: index,
          opens_at: '',
          closes_at: '',
          ...d,
          closed: d.closed === 1,
        };
      });
      return oh;
    },
  },
  methods: {
    ...mapActions('clinicStore', ['editOpeningHours']),
    mergeUpdatedHours() {
      const updated = this.updatedHours.reduce(
        (acc, oh) => ({
          ...acc,
          [oh.weekday]: oh,
        }),
        {}
      );
      return this.openingHoursCopy.map(oh => updated[oh.weekday] || oh);
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.loading = true;
        try {
          const { id: clinicId, timezone } = this.clinic;

          const data = {
            clinicId,
            openingHours: this.mergeUpdatedHours(),
          };

          if (timezone) {
            data.timezone = timezone;
          }

          await this.editOpeningHours(data);
          this.loading = false;
          this.errorMessage = '';
          this.displayError = false;

          this.isSaved = true;
          setTimeout(() => {
            this.isSaved = false;
          }, 1500);
        } catch (error) {
          this.loading = false;
          this.errorMessage = this.$t('opening_hours_error');
          this.displayError = true;
        }
      } else {
        this.errorMessage = this.$t('opening_hours_missing_error');
        this.displayError = true;
      }
    },
    changeHours(hours) {
      this.displayError = false;
      const { updatedHours } = this;
      const indexOfDay = updatedHours.findIndex(
        oh => oh.weekday === hours.weekday
      );
      const replace = indexOfDay !== -1 ? 1 : 0;
      updatedHours.splice(indexOfDay, replace, hours);
      updatedHours.sort((a, b) => {
        if (a.weekday > b.weekday) return 1;
        if (a.weekday < b.weekday) return -1;
        return 0;
      });
    },
  },
};
</script>
