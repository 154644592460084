<template>
  <div class="flex-1 flex flex-col space-y-4">
    <p
      v-if="error"
      class="text-red-700"
    >
      {{ error }}
    </p>
    <div class="flex justify-between space-x-4">
      <div class="flex-1 relative">
        <range-slider
          v-model="sliderValue"
          width="100%"
          :min="0"
          :max="100"
          :interval="5"
          tooltip="always"
          :disabled="uiState === 'updating'"
          :clickable="false"
          :rail-style="{
            color: '#00bed6'
          }"
          @drag-end="setRating"
        />
        <spinner-overlay :loading="uiState === 'updating'" />
      </div>
      <div>
        <text-button
          size="sm"
          @click="resetToDefaultValue"
        >
          Reset to default
        </text-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ClinicsApi } from '@/api';
import RangeSlider from '@/plugins/vue-ranger-slider';

export default {
  components: {
    RangeSlider,
  },
  props: {
    clinicId: {
      type: Number,
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    insuranceCompanyId: {
      type: [Number, undefined, null],
      default: null,
    },
  },
  data() {
    return {
      uiState: 'idle',
      sliderValue: this.$props.value,
      error: '',
    };
  },
  mounted() {
    this.sliderValue = this.value;
  },
  methods: {
    ...mapActions('clinicStore', ['updateClinic']),
    async resetToDefaultValue() {
      this.sliderValue = this.defaultValue;
      await this.setRating();
    },
    async setRating() {
      this.error = '';
      this.uiState = 'updating';
      try {
        if (this.property === 'insurance_rating') {
          await ClinicsApi.setInsuranceRating(
            this.clinicId,
            this.insuranceCompanyId,
            this.sliderValue
          );
        } else {
          await this.updateClinic({
            id: this.clinicId,
            [this.property]: this.sliderValue,
          });
        }
        this.$emit('updated');
        this.uiState = 'idle';
      } catch (error) {
        this.uiState = 'idle';
        this.error = error.message;
      }
    },
  },
};
</script>
