<template>
  <div class="flex items-center">
    <td class="w-1/6">
      <label>{{ $t(dayName) }}</label>
    </td>
    <td class="w-1/3">
      <ValidationProvider :rules="timeSelectRule">
        <v-select
          v-model="localHours.opens_at"
          class="mb-2"
          transition="none"
          :clearable="false"
          :options="hourOptions"
          :disabled="localHours.closed"
          @input="$emit('value-changed', localHours)"
        >
          <div slot="no-options">
            {{ $t('selected_time_is_in_wrong_format') }}
          </div>
        </v-select>
      </ValidationProvider>
    </td>
    <td class="w-1/3 ml-2">
      <ValidationProvider :rules="timeSelectRule">
        <v-select
          v-model="localHours.closes_at"
          class="mb-2 diagnosis-code-list"
          label="label"
          transition="none"
          :clearable="false"
          :options="hourOptions"
          :disabled="localHours.closed"
          @input="$emit('value-changed', localHours)"
        >
          <div slot="no-options">
            {{ $t('selected_time_is_in_wrong_format') }}
          </div>
        </v-select>
      </ValidationProvider>
    </td>
    <td class="w-1/5 flex justify-center ml-1">
      <input
        v-model="localHours.closed"
        type="checkbox"
        :checked="localHours.closed"
        @input="$emit('value-changed', localHours)"
      >
    </td>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: { vSelect },
  props: {
    openingHours: {
      type: Object,
      default: () => [],
    },
    hourOptions: {
      type: Array,
      default: () => [],
    },
    dayName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localHours: this.openingHours,
    };
  },
  computed: {
    timeSelectRule() {
      return !this.localHours.closed ? 'required' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vue-select/dist/vue-select.css';
</style>
