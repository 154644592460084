<template>
  <div
    class="bg-white pb-8"
    :class="{ shadow: !clinic.id, 'rounded border': showBorders }"
  >
    <div
      v-if="showMore"
      :id="`clinic_${clinic.id}`"
      class="px-4"
      data-parent="#clinic-list"
    >
      <p :id="`clinicName${clinic.id}`" class="pb-4 text-2xl font-black">
        {{ clinicName ? clinicName : '-' }}
      </p>

      <div
        v-if="clinic.id"
        id="statusAndSizeDiv"
        class="flex items-center justify-between pt-6"
      >
        <div class="flex w-2/3 items-center space-x-6">
          <div class="flex-1">
            <base-label for="clinicType">
              {{ $t('clinic_type') }}
            </base-label>
            <div class="max-w-sm">
              <ClinicDropdownEditor
                :clinic="clinic"
                :options="clinicTypes"
                property="clinic_type_id"
                name="clinicType"
              />
            </div>
          </div>
          <div class="flex-1">
            <base-label for="status">
              {{ $t('status') }}
            </base-label>
            <ClinicBooleanCheckbox
              id="status"
              :clinic="clinic"
              property="active"
              @edited="updateIsActive"
            />
            <div v-if="!isActive" class="pt-2 text-sm font-bold text-red-700">
              * Clinic is hidden on map
            </div>
            <div v-if="isActive" class="pt-2 text-sm font-bold text-green-700">
              * Clinic is visible on map
            </div>
          </div>
        </div>

        <div class="">
          <fv-icon
            v-tooltip="'Press any value to edit'"
            class="text-xl"
            icon="information"
          />
        </div>
      </div>

      <div class="flex flex-col">
        <div class="">
          <h4
            class="mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker"
          >
            Name and address
          </h4>
          <desc-list>
            <desc-item class="border-none">
              <desc-title>{{ $t('name') }}</desc-title>
              <desc-data>
                <ClinicDataEditor
                  :clinic="clinic"
                  property="name"
                  @edited="updateTitleData"
                />
              </desc-data>
            </desc-item>
            <desc-item class="border-none">
              <desc-title>{{ $t('address') }}</desc-title>
              <desc-data>
                <ClinicDataEditor
                  :clinic="clinic"
                  property="adress"
                  @edited="updateAddress"
                />
              </desc-data>
            </desc-item>
            <desc-item class="border-none">
              <desc-title>{{ $t('city') }}</desc-title>
              <desc-data>
                <ClinicDataEditor :clinic="clinic" property="city" />
              </desc-data>
            </desc-item>
            <desc-item class="border-none">
              <desc-title>Postcode</desc-title>
              <desc-data>
                <ClinicDataEditor :clinic="clinic" property="postcode" />
              </desc-data>
            </desc-item>
            <desc-item class="border-none">
              <desc-title>{{ $t('municipality') }}</desc-title>
              <desc-data>
                <ClinicDataEditor :clinic="clinic" property="municipality" />
              </desc-data>
            </desc-item>
            <template v-if="countries && countries.length">
              <desc-item class="border-none">
                <desc-title>{{ $t('country') }}</desc-title>
                <desc-data>
                  <ClinicDropdownEditor
                    :clinic="clinic"
                    :options="countries"
                    property="country_id"
                    name="country"
                  />
                </desc-data>
              </desc-item>
            </template>
          </desc-list>
        </div>

        <div class="">
          <h4
            class="mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker"
          >
            {{ $t('contact_information') }}
          </h4>
          <desc-list>
            <desc-item class="border-none">
              <desc-title>{{ $t('email') }}</desc-title>
              <desc-data>
                <ClinicDataEditor :clinic="clinic" property="email" />
              </desc-data>
            </desc-item>
            <desc-item class="border-none">
              <desc-title>{{ $t('phone') }}</desc-title>
              <desc-data>
                <ClinicDataEditor :clinic="clinic" property="phone" />
              </desc-data>
            </desc-item>
            <desc-item class="border-none">
              <desc-title>Fax</desc-title>
              <desc-data>
                <ClinicDataEditor :clinic="clinic" property="fax" />
              </desc-data>
            </desc-item>
            <desc-item class="border-none">
              <desc-title>{{ $t('website') }}</desc-title>
              <desc-data>
                <ClinicDataEditor :clinic="clinic" property="homepage" />
              </desc-data>
            </desc-item>
            <desc-item class="border-none">
              <desc-title>{{ $t('email_referral') }}</desc-title>
              <desc-data>
                <ClinicDataEditor :clinic="clinic" property="referral_email" />
              </desc-data>
            </desc-item>
            <desc-item class="border-none">
              <desc-title>{{ $t('phone_referral') }}</desc-title>
              <desc-data>
                <ClinicDataEditor :clinic="clinic" property="referral_phone" />
              </desc-data>
            </desc-item>
          </desc-list>
        </div>
      </div>

      <div>
        <div class="flex flex-col">
          <div v-if="clinic.id" class="flex flex-col space-y-2">
            <h4
              class="mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker"
            >
              {{ $t('size_general_info') }}
            </h4>
            <desc-list>
              <desc-item class="border-none">
                <desc-title>{{ $t('general_info') }}</desc-title>
                <desc-data class="w-75">
                  <ClinicDataEditor
                    :clinic="clinic"
                    property="info"
                    html-element="textarea"
                    :required="false"
                  />
                </desc-data>
              </desc-item>
              <desc-item class="border-none">
                <desc-title>{{ $t('referral_information') }}</desc-title>
                <desc-data class="text-truncate">
                  <ClinicDataEditor
                    html-element="textarea"
                    :clinic="clinic"
                    property="referral_info"
                  />
                </desc-data>
              </desc-item>
              <desc-item class="border-none">
                <desc-title>{{ $t('information_horse_service') }}</desc-title>
                <desc-data class="w-75">
                  <ClinicDataEditor
                    :clinic="clinic"
                    property="info_horse"
                    html-element="textarea"
                    :required="false"
                  />
                </desc-data>
              </desc-item>
            </desc-list>
          </div>

          <div class="flex flex-col space-y-8">
            <div class="flex flex-col space-y-4">
              <h4
                class="mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker"
              >
                Location
              </h4>
              <desc-list>
                <desc-item class="border-none">
                  <desc-title for="lat">
                    {{ $t('latitude') }}
                  </desc-title>
                  <desc-data>
                    <p id="lat">
                      {{ lat }}
                    </p>
                  </desc-data>
                </desc-item>
                <desc-item class="border-none">
                  <desc-title for="lng">
                    {{ $t('longitude') }}
                  </desc-title>
                  <desc-data>
                    <p id="lng">
                      {{ lng }}
                    </p>
                  </desc-data>
                </desc-item>
              </desc-list>
              <GeocodeAdress
                :clinic-id="clinic.id"
                :address="address"
                @coordinates-persisted="setCoordinates($event)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <ClinicAnimalType
          v-if="clinic.id"
          :animal-type-list="clinic.animal_types"
          :clinic-id="clinic.id"
          @edited="updateTypes($event)"
        />
        <ClinicServiceSelector :clinic="clinic" />
      </div>

      <div class="flex space-x-12">
        <ClinicOpeningHours
          :clinic-id="clinic.id"
          :clinic="clinic"
          :opening-hours="clinic.opening_hours"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ClinicAnimalType from './ClinicAnimalType.vue';
import ClinicOpeningHours from './ClinicOpeningHours.vue';
import ClinicServiceSelector from './services/ClinicServiceSelector.vue';
import ClinicDropdownEditor from './form/ClinicDropdownEditor.vue';
import ClinicBooleanCheckbox from './form/ClinicBooleanCheckbox.vue';
import ClinicDataEditor from './form/ClinicDataEditor.vue';
import GeocodeAdress from './geocoder/GeocodeAdress.vue';

export default {
  components: {
    ClinicDataEditor,
    ClinicAnimalType,
    ClinicOpeningHours,
    ClinicDropdownEditor,
    ClinicServiceSelector,
    ClinicBooleanCheckbox,
    GeocodeAdress,
  },
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
    showBorders: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMore: true,
      clinicName: this.clinic.name,
      lat: this.clinic.lat,
      lng: this.clinic.lng,
      isActive: this.clinic.active,
      animalTypes: this.clinic.animal_types,
      address: this.clinic.city
        ? `${this.clinic.adress} ${this.clinic.city} ${this.clinic.country.name}`
        : this.clinic.adress + ' ' + this.clinic.country.name,
    };
  },
  computed: {
    ...mapState('clinicStore', ['clinicTypes', 'countries']),
  },
  async mounted() {
    // Fetch everything again if page is refreshed
    if (!this.clinicTypes.length) {
      await Promise.all([
        this.fetchAnimalTypes(),
        this.fetchServiceOptions(),
        this.fetchStationaryCareOptions(),
        this.fetchServiceCategories(),
        this.fetchServiceAnimalGroups(),
        this.fetchEmergencyCareOptions(),
        this.fetchAvailableDays(),
        this.fetchClinicTypes({ country_id: this.countryId }),
      ]);
    }
  },
  methods: {
    ...mapActions('clinicStore', [
      'fetchAnimalTypes',
      'fetchServiceOptions',
      'fetchStationaryCareOptions',
      'fetchServiceCategories',
      'fetchServiceAnimalGroups',
      'fetchEmergencyCareOptions',
      'fetchAvailableDays',
      'fetchClinicTypes',
    ]),
    updateTitleData(clone) {
      this.clinicName = clone.name;
    },
    updateIsActive(clone) {
      this.isActive = clone.active;
    },
    setCoordinates(payload) {
      this.lat = payload.lat;
      this.lng = payload.lng;
    },
    updateTypes(payload) {
      this.animalTypes = payload;
    },
    updateAddress(payload) {
      this.address = payload.adress;
    },
    scrollToHeader(id) {
      setTimeout(() => {
        const element = document.getElementById(id);
        const yOffset = -20;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.clinic-name-title {
  color: #4a5568;
  font-weight: 700;
  font-size: 1.8rem;
}
.clinic-header {
  cursor: pointer;
}
.clinic-header:hover {
  background: #f7fafc;
}
.clinic-icon {
  color: #718096;
}
</style>
