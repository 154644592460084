<template>
  <div class="flex flex-col space-y-4">
    <div class="flex flex-col space-y-2">
      <h4 class="block text-primary-darker font-semibold text-lg">
        Rating
      </h4>
      <desc-list>
        <desc-item>
          <desc-title>
            Price rating
          </desc-title>
          <desc-data>
            <clinic-rating-slider
              class="py-4"
              :value="clinic.rating_price"
              :clinic-id="clinic.id"
              property="rating_price"
              :default-value="1"
              @updated="$emit('updated')"
            />
          </desc-data>
        </desc-item>
        <desc-item>
          <desc-title>
            Quality rating
          </desc-title>
          <desc-data>
            <clinic-rating-slider
              class="pt-4 pb-8"
              :value="clinic.rating_quality"
              :clinic-id="clinic.id"
              property="rating_quality"
              :default-value="50"
            />
          </desc-data>
        </desc-item>
        <desc-item
          v-for="icRating in clinic.insurance_company_ratings"
          :key="clinic.id + icRating.insurance_id"
        >
          <desc-title>
            {{ icRating.name }} rating
          </desc-title>
          <desc-data>
            <clinic-rating-slider
              class="pt-4 pb-8"
              :value="icRating.rating"
              :insurance-company-id="icRating.insurance_id"
              :clinic-id="clinic.id"
              property="insurance_rating"
              :default-value="100"
              @updated="$emit('updated')"
            />
          </desc-data>
        </desc-item>
      </desc-list>
    </div>
  </div>
</template>

<script>
import ClinicRatingSlider from '@/components/clinic-map/clinic/form/ClinicRatingSlider';

export default {
  components: {
    ClinicRatingSlider,
  },
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    clinicId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="postcss">
.slider {
  width: auto;
}
.range-slider-fill {
  background-color: #00bed6;
}
</style>
