<template>
  <div>
    <span
      v-if="error"
      class="text-red-600"
    >{{ error }}</span>

    <form
      v-else
      class="mt-1"
      @submit.prevent="submitEditClinic"
    >
      <div class="inline-flex items-center">
        <label
          for="active"
          class="inline-flex items-center space-x-1"
        >
          <input
            v-model="clinicClone[property]"
            type="checkbox"
            :disabled="loading"
            name="active"
            aria-label="Active"
            @change="submitEditClinic"
          >
          <span
            class=""
          ><small>{{ clinicClone[property] ? $t('Active*') : $t('Activate*') }}</small></span>
        </label>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { clone } from 'lodash';

export default {
  props: {
    property: {
      type: String,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      clinicClone: clone(this.clinic),
      loading: false,
      error: '',
    };
  },
  methods: {
    ...mapActions('clinicStore', ['updateClinic']),
    async submitEditClinic() {
      this.error = '';

      if (this.clinicClone[this.property] === this.clinic[this.property]) {
        return;
      }

      this.loading = true;
      try {
        const data = await this.updateClinic({
          id: this.clinic.id,
          [this.property]: this.clinicClone[this.property],
        });
        console.log('Saved clinic data', data);
        this.loading = false;
        this.$emit('edited', this.clinicClone);
      } catch (error) {
        this.error = error;
        this.clinicClone[this.property] = this.clinic[this.property];
        this.loading = false;
      }
    },
    setProperty(event) {
      this.clinicClone[this.property] = event.target.value;
    },
    focusOnInput() {
      this.$nextTick(() => {
        const el = document.getElementById(`inputField${this.clinic.id}`);
        console.log(el);
        el.focus();
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.clinic-data-link {
  color: rgb(40, 59, 68);
  cursor: text !important;
}
dt {
  color: rgb(158, 158, 158);
}
</style>
