<template>
  <base-modal :show="showModal" @close="toggleModal">
    <slot>
      <h2
        class="mb-4 flex w-full items-center space-x-2 border-b pb-2 font-display text-2xl"
      >
        <fv-icon icon="lifebuoy" size="lg" />
        <span>Submit follow-up on referral {{ refId }}</span>
      </h2>
      <!-- error message -->
      <div class="mb-4 mt-2 flex w-full flex-wrap">
        <div v-if="errorMessage" id="errors" class="w-full">
          <div
            class="mb-4 flex rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
            role="alert"
          >
            <span class="sr-only">Info</span>
            <div>
              <p>{{ errorMessage }}</p>
            </div>
          </div>
        </div>

        <base-label for="message"> Message </base-label>
        <textarea-input
          id="message"
          v-model="textMessage"
          class="w-full"
          name="message"
          placeholder=""
        />
      </div>
    </slot>
    <div slot="buttons" class="flex w-full justify-end space-x-2 p-2">
      <base-button color="cancel" @click="toggleModal"> Cancel </base-button>
      <base-button
        :loading="loading"
        color="primary"
        :disabled="loading"
        @click="submit"
      >
        Send
      </base-button>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/UI/modals/base-modal';
import { ClinicsApi } from '@/api';

export default {
  name: 'ClinicFollowUpModal',
  components: {
    BaseModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    refId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      textMessage: '',
      hide: false,
      loading: false,
      errorMessage: null,
    };
  },
  methods: {
    toggleModal() {
      this.$emit('toggle');
    },
    async submit() {
      try {
        const response = await ClinicsApi.storeReferralFollowup({
          message: this.textMessage,
          appointment_referral_id: this.refId,
        });
        this.$emit('addFollowup', response.AppointmentReferralFollowup);
        this.textMessage = '';
        this.toggleModal();
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
};
</script>

<style scoped></style>
