var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: { show: _vm.show },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("spinner-overlay", {
            attrs: { color: "light", loading: _vm.loading },
          }),
          !_vm.clinicCreated
            ? _c(
                "div",
                [
                  _c("div", [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "font-display text-2xl font-bold text-center mb-6 mt-2",
                      },
                      [_vm._v(" Add a clinic ")]
                    ),
                  ]),
                  _c(
                    "ValidationObserver",
                    {
                      ref: "observer",
                      staticClass: "space-y-8 px-4 mb-4",
                      attrs: { tag: "form" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "title-row",
                            {
                              staticClass: "block  font-semibold text-lg mb-4",
                            },
                            [_vm._v(" Name and address ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-1 md:grid-cols-2 gap-4",
                            },
                            [
                              _c("ValidationProvider", {
                                staticClass: "w-full",
                                attrs: {
                                  rules: "required",
                                  name: "name",
                                  tag: "div",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("base-label", [
                                            _vm._v(" Name "),
                                            _c(
                                              "span",
                                              { staticClass: "text-gray-700" },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                          _c("base-input", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              id: "name",
                                              name: "name",
                                              placeholder: "Name",
                                            },
                                            model: {
                                              value: _vm.form.name,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "name", $$v)
                                              },
                                              expression: "form.name",
                                            },
                                          }),
                                          _c(
                                            "transition",
                                            { attrs: { name: "fade" } },
                                            [
                                              errors.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-red-800 mt-1 font-semibold text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(errors[0]) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  403216203
                                ),
                              }),
                              _c("ValidationProvider", {
                                staticClass: "w-full",
                                attrs: {
                                  rules: "required",
                                  name: "address",
                                  tag: "div",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("base-label", [
                                            _vm._v(" Address "),
                                            _c(
                                              "span",
                                              { staticClass: "text-gray-700" },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                          _c("base-input", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              id: "address",
                                              name: "address",
                                              placeholder: "Address",
                                            },
                                            model: {
                                              value: _vm.form.adress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "adress",
                                                  $$v
                                                )
                                              },
                                              expression: "form.adress",
                                            },
                                          }),
                                          _c(
                                            "transition",
                                            { attrs: { name: "fade" } },
                                            [
                                              errors.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-red-800 mt-1 font-semibold text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(errors[0]) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3904963902
                                ),
                              }),
                              _c("ValidationProvider", {
                                staticClass: "w-full",
                                attrs: {
                                  rules: "required",
                                  name: "postcode",
                                  tag: "div",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("base-label", [
                                            _vm._v(" Postcode "),
                                            _c(
                                              "span",
                                              { staticClass: "text-gray-700" },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                          _c("base-input", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              id: "postcode",
                                              name: "postcode",
                                              placeholder: "Postcode",
                                            },
                                            model: {
                                              value: _vm.form.postcode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "postcode",
                                                  $$v
                                                )
                                              },
                                              expression: "form.postcode",
                                            },
                                          }),
                                          _c(
                                            "transition",
                                            { attrs: { name: "fade" } },
                                            [
                                              errors.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-red-800 mt-1 font-semibold text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(errors[0]) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1108557113
                                ),
                              }),
                              _c("ValidationProvider", {
                                staticClass: "w-full md:1/2",
                                attrs: {
                                  rules: "required",
                                  name: "city",
                                  tag: "div",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("base-label", [
                                            _vm._v(" City "),
                                            _c(
                                              "span",
                                              { staticClass: "text-gray-700" },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                          _c("base-input", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              id: "city",
                                              name: "city",
                                              placeholder: "City",
                                            },
                                            model: {
                                              value: _vm.form.city,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "city", $$v)
                                              },
                                              expression: "form.city",
                                            },
                                          }),
                                          _c(
                                            "transition",
                                            { attrs: { name: "fade" } },
                                            [
                                              errors.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-red-800 mt-1 font-semibold text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(errors[0]) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1877155211
                                ),
                              }),
                              _c(
                                "ValidationProvider",
                                { attrs: { name: "municipality", tag: "div" } },
                                [
                                  _c("base-label", [_vm._v(" County ")]),
                                  _c("base-input", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      id: "municipality",
                                      name: "municipality",
                                      placeholder: "County",
                                    },
                                    model: {
                                      value: _vm.form.municipality,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "municipality", $$v)
                                      },
                                      expression: "form.municipality",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.platform !== "sv"
                                ? _c("ValidationProvider", {
                                    staticClass: "w-full",
                                    attrs: {
                                      rules: "required",
                                      name: "country",
                                      tag: "div",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("base-label", [
                                                _vm._v(" Country "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-gray-700",
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                              ]),
                                              _c("custom-select", {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  id: "countryId",
                                                  name: "countryId",
                                                  size: "sm",
                                                  "label-size": "sm",
                                                  "label-attr": "name",
                                                  "value-attr": "id",
                                                  searchable: true,
                                                  options:
                                                    _vm.filteredCountries,
                                                  value: _vm.form.country_id,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.form.country_id = $event
                                                  },
                                                },
                                              }),
                                              _c(
                                                "transition",
                                                { attrs: { name: "fade" } },
                                                [
                                                  errors.length
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-red-800 mt-1 font-semibold text-sm",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                errors[0]
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1859801472
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "title-row",
                            {
                              staticClass: "block  font-semibold text-lg mb-4",
                            },
                            [_vm._v(" Clinic Details ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-1 md:grid-cols-2 gap-4",
                            },
                            [
                              _c("ValidationProvider", {
                                staticClass: "w-full",
                                attrs: {
                                  rules: "required",
                                  name: "clinic type",
                                  tag: "div",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("base-label", [
                                            _vm._v(" Clinic Type "),
                                            _c(
                                              "span",
                                              { staticClass: "text-gray-700" },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                          _c("custom-select", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              id: "clinic type",
                                              name: "clinic type",
                                              size: "sm",
                                              "label-size": "sm",
                                              "label-attr": "name",
                                              "value-attr": "id",
                                              searchable: true,
                                              options: _vm.filteredClinicTypes,
                                              value: _vm.form.clinic_type_id,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.form.clinic_type_id = $event
                                              },
                                            },
                                          }),
                                          _c(
                                            "transition",
                                            { attrs: { name: "fade" } },
                                            [
                                              errors.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-red-800 mt-1 font-semibold text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(errors[0]) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  883380117
                                ),
                              }),
                              _c(
                                "ValidationProvider",
                                {
                                  staticClass: "w-full",
                                  attrs: { name: "status", tag: "div" },
                                },
                                [
                                  _c("base-label", [
                                    _vm._v(" Status "),
                                    _c(
                                      "span",
                                      { staticClass: "text-gray-700" },
                                      [_vm._v("*")]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "my-2 flex items-center max-h-full",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "text-sm mr-2",
                                          class: {
                                            "text-gray-500": _vm.form.active,
                                          },
                                          attrs: { for: "sign" },
                                        },
                                        [_vm._v(" Inactive ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-switch max-h-full",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.form.active,
                                                expression: "form.active",
                                              },
                                            ],
                                            staticClass: "form-switch-checkbox",
                                            attrs: {
                                              id: "sign",
                                              type: "checkbox",
                                              name: "sign",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.form.active
                                              )
                                                ? _vm._i(
                                                    _vm.form.active,
                                                    null
                                                  ) > -1
                                                : _vm.form.active,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.form.active,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "active",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "active",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "active",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c("label", {
                                            staticClass:
                                              "form-switch-label display-none",
                                            attrs: { for: "sign" },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "text-sm",
                                          class: {
                                            "text-gray-500": !_vm.form.active,
                                          },
                                          attrs: { for: "sign" },
                                        },
                                        [_vm._v(" Active ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "title-row",
                            {
                              staticClass: "block  font-semibold text-lg mb-4",
                            },
                            [_vm._v(" Contact Details ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-1 md:grid-cols-2 gap-4",
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  rules: { required: false, email: true },
                                  name: "email",
                                  tag: "div",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("base-label", [_vm._v(" Email ")]),
                                          _c("base-input", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              id: "email",
                                              name: "email",
                                              placeholder: "Email",
                                            },
                                            model: {
                                              value: _vm.form.email,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "email", $$v)
                                              },
                                              expression: "form.email",
                                            },
                                          }),
                                          _c(
                                            "transition",
                                            { attrs: { name: "fade" } },
                                            [
                                              errors.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-red-800 mt-1 font-semibold text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(errors[0]) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1585871412
                                ),
                              }),
                              _c("ValidationProvider", {
                                attrs: {
                                  rules: { required: true, email: true },
                                  name: "referral email",
                                  tag: "div",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("base-label", [
                                            _vm._v(" Referral Email "),
                                            _c(
                                              "span",
                                              { staticClass: "text-gray-700" },
                                              [_vm._v("*")]
                                            ),
                                          ]),
                                          _c("base-input", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              id: "referral email",
                                              name: "referral email",
                                              placeholder: "Referral Email",
                                            },
                                            model: {
                                              value: _vm.form.referral_email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "referral_email",
                                                  $$v
                                                )
                                              },
                                              expression: "form.referral_email",
                                            },
                                          }),
                                          _c(
                                            "transition",
                                            { attrs: { name: "fade" } },
                                            [
                                              errors.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-red-800 mt-1 font-semibold text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(errors[0]) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3350661798
                                ),
                              }),
                              _c(
                                "ValidationProvider",
                                { attrs: { name: "phone", tag: "div" } },
                                [
                                  _c("base-label", [_vm._v(" Phone ")]),
                                  _c("base-input", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      id: "phone",
                                      name: "phone",
                                      placeholder: "Phone",
                                    },
                                    model: {
                                      value: _vm.form.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "phone", $$v)
                                      },
                                      expression: "form.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "ValidationProvider",
                                { attrs: { name: "homepage", tag: "div" } },
                                [
                                  _c("base-label", [_vm._v(" Website ")]),
                                  _c("base-input", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      id: "homepage",
                                      name: "homepage",
                                      placeholder: "Website",
                                    },
                                    model: {
                                      value: _vm.form.homepage,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "homepage", $$v)
                                      },
                                      expression: "form.homepage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "flex flex-col space-y-8" }, [
                _c("div", [
                  _c(
                    "h2",
                    { staticClass: "text-2xl font-bold text-center mb-6 mt-2" },
                    [_vm._v(" " + _vm._s(_vm.clinic.name) + " ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex bg-azure-25 text-navy px-4 py-3 rounded-lg mt-1",
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "text-gray-600",
                        attrs: { icon: "info-circle" },
                      }),
                      _c("p", { staticClass: "ml-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.lng && !_vm.lat
                                ? "Clinic successfully added. Set location details below."
                                : "Location saved!"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "flex flex-col space-y-4" },
                  [
                    _c("h4", { staticClass: "block  font-semibold text-lg" }, [
                      _vm._v(" Location "),
                    ]),
                    _c(
                      "desc-list",
                      [
                        _c(
                          "desc-item",
                          [
                            _c("desc-title", { attrs: { for: "lat" } }, [
                              _vm._v(" " + _vm._s(_vm.$t("latitude")) + " "),
                            ]),
                            _c("desc-data", [
                              _c("p", { attrs: { id: "lat" } }, [
                                _vm._v(" " + _vm._s(_vm.lat) + " "),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          [
                            _c("desc-title", { attrs: { for: "lng" } }, [
                              _vm._v(" " + _vm._s(_vm.$t("longitude")) + " "),
                            ]),
                            _c("desc-data", [
                              _c("p", { attrs: { id: "lng" } }, [
                                _vm._v(" " + _vm._s(_vm.lng) + " "),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("GeocodeAdress", {
                      attrs: {
                        "clinic-id": _vm.clinic.id,
                        address: _vm.clinic.adress,
                      },
                      on: {
                        "coordinates-persisted": function ($event) {
                          return _vm.setCoordinates($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
        ]
      }),
      _c(
        "div",
        {
          staticClass: "p-2 w-full flex space-x-2 justify-end",
          attrs: { slot: "buttons" },
          slot: "buttons",
        },
        [
          _c(
            "base-button",
            {
              attrs: { color: "cancel" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close", false)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.clinicCreated ? "Close" : "Cancel") + " ")]
          ),
          !_vm.clinicCreated
            ? _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    color: "primary",
                    disabled: _vm.loading,
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v(" Save ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }