var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative flex flex-1" },
    [
      _vm.clinic || _vm.error
        ? _c(
            "card",
            { staticClass: "mx-auto flex h-full min-h-full w-full lg:w-2/3" },
            [
              _c("p", [_vm._v(_vm._s(_vm.error))]),
              _vm.error || _vm.uiState === "error"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mb-3 flex items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900",
                    },
                    [
                      _c(
                        "span",
                        [_c("fv-icon", { attrs: { icon: "warning" } })],
                        1
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.error))]),
                    ]
                  )
                : _vm._e(),
              _vm.clinic
                ? _c("div", { staticClass: "w-full" }, [
                    _c("div", { staticClass: "flex flex-col space-y-4 p-4" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col space-y-1 border-gray-300 pb-4",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col space-y-2 md:flex-row md:items-center md:justify-between",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-display text-3xl font-black",
                                },
                                [_vm._v(" " + _vm._s(_vm.clinic.name) + " ")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "inline-flex items-center space-x-1 text-primary-darkest",
                                  attrs: { to: { name: "clinics" } },
                                },
                                [
                                  _c("fv-icon", {
                                    attrs: { icon: "chevron-left" },
                                  }),
                                  _c("span", [_vm._v("Back")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "text-gray-700" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.clinic.clinic_type &&
                                    _vm.clinic.clinic_type.name
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex w-full justify-between border-b" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex space-x-2 text-sm lg:space-x-6 lg:text-base",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "focus:outline-none border-b-2 border-transparent pb-2 hover:text-primary-darkest",
                                  class: {
                                    "border-primary":
                                      _vm.view === "information",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.view = "information"
                                    },
                                  },
                                },
                                [_vm._v(" Information ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "focus:outline-none border-b-2 border-transparent pb-2 hover:text-primary-darkest",
                                  class: {
                                    "border-primary": _vm.view === "referrals",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.view = "referrals"
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Referrals "),
                                  _c("span", { staticClass: "text-sm" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(_vm.clinic.referrals.length) +
                                        ")"
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "focus:outline-none border-b-2 border-transparent pb-2 hover:text-primary-darkest",
                                  class: {
                                    "border-primary": _vm.view === "rating",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.view = "rating"
                                    },
                                  },
                                },
                                [_vm._v(" Rating ")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex space-x-1 pb-2" },
                            [
                              _vm.$can("manage", "All")
                                ? _c(
                                    "base-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleEdit(_vm.clinic)
                                        },
                                      },
                                    },
                                    [
                                      _c("fv-icon", {
                                        attrs: { icon: "edit-alt" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.view === "information",
                              expression: "view === 'information'",
                            },
                          ],
                          staticClass: "flex flex-col space-y-6",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-between md:w-full lg:w-2/3",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex flex-col" },
                                [
                                  _c("base-label", [_vm._v(" Address: ")]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-col space-y-1 text-gray-700",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.clinic.adress)),
                                      ]),
                                      _c("p", [
                                        _vm.clinic.postcode
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.clinic.postcode) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.clinic.city
                                          ? _c("span", [
                                              _vm._v(_vm._s(_vm.clinic.city)),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _vm.clinic.municipality
                                        ? _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.clinic.municipality
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "flex flex-col" },
                                [
                                  _c("base-label", [_vm._v(" Coordinates: ")]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-col space-y-1 text-gray-700",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "Latitude: " + _vm._s(_vm.clinic.lat)
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "Longitude: " + _vm._s(_vm.clinic.lng)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex flex-col" },
                            [
                              _c("base-label", [_vm._v(" Active: ")]),
                              _vm.clinic.active
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "flex flex-col space-y-1 text-gray-700",
                                    },
                                    [_vm._v(" Clinic is visible on map ")]
                                  )
                                : _vm._e(),
                              !_vm.clinic.active
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "flex flex-col space-y-1 text-gray-700",
                                    },
                                    [_vm._v(" Clinic is hidden on map ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex flex-col" },
                            [
                              _c("base-label", [_vm._v(" Contact: ")]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-col space-y-1 text-gray-700",
                                },
                                [
                                  _c("p", [_vm._v(_vm._s(_vm.clinic.email))]),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.clinic.referral_email &&
                                            _vm.clinic.referral_email +
                                              " (referral email)"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.clinic.phone) + " (phone)"
                                    ),
                                  ]),
                                  _vm.clinic.fax
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.clinic.fax) + " (fax)"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.clinic.homepage
                                    ? _c("p", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-blue-700",
                                            attrs: {
                                              href: _vm.clinic.homepage,
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.clinic.homepage))]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.clinic.info
                            ? _c(
                                "div",
                                { staticClass: "flex flex-col" },
                                [
                                  _c("base-label", [_vm._v(" Information: ")]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-col space-y-1 text-gray-700",
                                    },
                                    [
                                      _c("p", { staticClass: "max-w-md" }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.clinic.info) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "flex flex-col" },
                            [
                              _c("base-label", [_vm._v(" Animal types: ")]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-col space-y-1 text-gray-700",
                                },
                                [
                                  !_vm.clinic.animal_types.length
                                    ? _c("span", [
                                        _vm._v(
                                          "No animal types have been specified."
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.clinic.animal_types,
                                    function (animalType) {
                                      return _c(
                                        "span",
                                        {
                                          key: _vm.clinic.id + animalType.id,
                                          staticClass: "text-gray-700",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(animalType.name) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex w-full flex-col" },
                            [
                              _c("base-label", [_vm._v(" Opening hours: ")]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-col space-y-1 text-gray-700",
                                },
                                [
                                  _c("p", { staticClass: "mb-2" }, [
                                    _vm._v(" On call service: "),
                                    _c("span", {
                                      staticClass: "font-semibold",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.clinic.on_call ? "Yes" : "No"
                                        ),
                                      },
                                    }),
                                  ]),
                                  _vm._l(
                                    _vm.clinic.opening_hours,
                                    function (openingHour) {
                                      return _c(
                                        "p",
                                        {
                                          key:
                                            _vm.clinic.id + openingHour.weekday,
                                          staticClass: "text-gray-700",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getWeekdayName(openingHour)
                                              ) +
                                              ": "
                                          ),
                                          openingHour.closed
                                            ? _c("span", [_vm._v("Closed")])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(openingHour.opens_at) +
                                                    " - " +
                                                    _vm._s(
                                                      openingHour.closes_at
                                                    )
                                                ),
                                              ]),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex flex-col" },
                            [
                              _c("base-label", [_vm._v(" Services: ")]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-col space-y-1 text-gray-700",
                                },
                                [
                                  !_vm.clinic.services.length
                                    ? _c("span", [
                                        _vm._v(
                                          "No services have been specified."
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.clinic.services,
                                    function (service) {
                                      return _c(
                                        "span",
                                        {
                                          key: _vm.clinic.id + service.id,
                                          staticClass: "text-gray-700",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(service.name) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.view === "referrals",
                              expression: "view === 'referrals'",
                            },
                          ],
                        },
                        [
                          _vm.clinic.referrals.length
                            ? _c("div", [_c("clinic-referral")], 1)
                            : _c(
                                "p",
                                {
                                  staticClass:
                                    "rounded border bg-azure-25 px-5 py-4 text-navy",
                                },
                                [_vm._v(" This clinic has no referrals. ")]
                              ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.view === "rating",
                              expression: "view === 'rating'",
                            },
                          ],
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "max-w-4xl" },
                            [
                              _vm.view === "rating"
                                ? _c("clinic-rating-form", {
                                    attrs: {
                                      "clinic-id": _vm.clinic.id,
                                      clinic: _vm.clinic,
                                    },
                                    on: { updated: _vm.fetchClinic },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.editClinic
        ? _c(
            "base-modal",
            {
              attrs: { show: _vm.showEditForm },
              on: {
                close: function ($event) {
                  ;(_vm.editClinic = null),
                    (_vm.showEditForm = false),
                    _vm.fetchClinic()
                },
              },
            },
            [
              _c("clinic-edit-card", {
                attrs: { "show-borders": false, clinic: _vm.editClinic },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }