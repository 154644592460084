var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex flex-col space-y-4",
      staticStyle: { "min-height": "20vh" },
    },
    [
      _c(
        "h4",
        {
          staticClass:
            "block text-primary-darker text-lg font-semibold border-t border-gray-400 pt-3 mt-3",
        },
        [_vm._v(" " + _vm._s(_vm.$t("services")) + " ")]
      ),
      _c(
        "desc-list",
        [
          _c(
            "desc-item",
            { staticClass: "border-none" },
            [
              _c("desc-title", [
                _vm._v(" " + _vm._s(_vm.$t("add_specific_service")) + " "),
              ]),
              _c("desc-data", { staticClass: "mt-4" }, [
                _c(
                  "form",
                  {
                    ref: "servicesForm",
                    attrs: { name: "serviceList" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.updateServices.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {},
                      [
                        _c(
                          "base-label",
                          {
                            staticClass: "mb-2 mt-3 block",
                            attrs: { for: _vm._uid + "-animal_group" },
                          },
                          [
                            _c("small", [
                              _vm._v(_vm._s(_vm.$t("animal_group"))),
                            ]),
                          ]
                        ),
                        _c("v-select", {
                          staticClass: "form-control",
                          attrs: {
                            id: _vm._uid + "-animal_group",
                            name: "selectedAnimalGroup",
                            label: "name",
                            reduce: function (opt) {
                              return opt.id
                            },
                            options: _vm.clinicServiceAnimalGroups,
                          },
                          model: {
                            value: _vm.selectedAnimalGroup,
                            callback: function ($$v) {
                              _vm.selectedAnimalGroup = $$v
                            },
                            expression: "selectedAnimalGroup",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {},
                      [
                        _c(
                          "base-label",
                          {
                            staticClass: "mb-2 mt-3 block",
                            attrs: { for: _vm._uid + "-category" },
                          },
                          [
                            _c("small", [
                              _vm._v(_vm._s(_vm.$t("service_category"))),
                            ]),
                          ]
                        ),
                        _c("v-select", {
                          attrs: {
                            id: _vm._uid + "-category",
                            name: "serviceCategory",
                            options: _vm.clinicServiceCategories,
                            label: "name",
                            reduce: function (opt) {
                              return opt.id
                            },
                          },
                          model: {
                            value: _vm.selectedCategory,
                            callback: function ($$v) {
                              _vm.selectedCategory = $$v
                            },
                            expression: "selectedCategory",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _vm.error
                          ? _c("p", { staticClass: "text-red-600" }, [
                              _vm._v(" " + _vm._s(_vm.error) + " "),
                            ])
                          : _vm._e(),
                        _vm._l(_vm.availableServices, function (service) {
                          return _c(
                            "div",
                            { key: service.id, staticClass: "my-1" },
                            [
                              service.category_id == _vm.selectedCategory
                                ? [
                                    _c(
                                      "label",
                                      { attrs: { for: service.key } },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: service.attached,
                                              expression: "service.attached",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            disabled: _vm.loading,
                                            name: service.key,
                                            "true-value": true,
                                            "false-value": false,
                                          },
                                          domProps: {
                                            checked: service.attached,
                                            value: service.id,
                                            checked: Array.isArray(
                                              service.attached
                                            )
                                              ? _vm._i(
                                                  service.attached,
                                                  service.id
                                                ) > -1
                                              : service.attached,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = service.attached,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = service.id,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      service,
                                                      "attached",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      service,
                                                      "attached",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  service,
                                                  "attached",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _c("small", [
                                            _vm._v(_vm._s(service.name)),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        _c(
                          "div",
                          { staticClass: "mt-4 text-right" },
                          [
                            _c(
                              "base-button",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.availableServices.length ||
                                    !_vm.selectedCategory ||
                                    !_vm.selectedAnimalGroup,
                                  type: "submit",
                                  loading: _vm.loading,
                                  size: "lg",
                                  color: "primary",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("save_services")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "desc-item",
            { staticClass: "border-none" },
            [
              _c("desc-title", [_vm._v(_vm._s(_vm.$t("availability")))]),
              _c("desc-data", { staticClass: "mt-1" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "base-label",
                      {
                        staticClass: "mb-2 mt-3 block",
                        attrs: { for: "emergencyCareOption" },
                      },
                      [
                        _c("small", [
                          _vm._v(_vm._s(_vm.$t("emergency_care_options"))),
                        ]),
                      ]
                    ),
                    _c("ClinicDropdownEditor", {
                      attrs: {
                        clinic: _vm.clinic,
                        options: _vm.clinicEmergencyCareOptions,
                        property: "emergency_care_option",
                        name: "emergencyCareOption",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "base-label",
                      {
                        staticClass: "mb-1 mt-3 block",
                        attrs: { for: "onCallOption" },
                      },
                      [_c("small", [_vm._v(_vm._s(_vm.$t("on_call_options")))])]
                    ),
                    _c("ClinicDropdownEditor", {
                      attrs: {
                        clinic: _vm.clinic,
                        options: _vm.clinicOnCallOptions,
                        property: "on_call",
                        name: "onCall",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "base-label",
                      {
                        staticClass: "mb-2 mt-3 block",
                        attrs: { for: "clinicStationaryCarePets" },
                      },
                      [
                        _c("small", [
                          _vm._v(
                            _vm._s(_vm.$t("inpatient_care_small_animals"))
                          ),
                        ]),
                      ]
                    ),
                    _c("ClinicDropdownEditor", {
                      attrs: {
                        clinic: _vm.clinic,
                        options: _vm.clinicStationaryCareOptions,
                        property: "stationary_care_pets",
                        name: "clinicStationaryCarePets",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "base-label",
                      {
                        staticClass: "mb-2 mt-3 block",
                        attrs: { for: "clinicStationaryCareHorses" },
                      },
                      [
                        _c("small", [
                          _vm._v(_vm._s(_vm.$t("inpatient_care_horses")) + " "),
                        ]),
                      ]
                    ),
                    _c("ClinicDropdownEditor", {
                      attrs: {
                        clinic: _vm.clinic,
                        options: _vm.clinicStationaryCareOptions,
                        property: "stationary_care_horses",
                        name: "clinicStationaryCareHorses",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }