var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    { attrs: { show: _vm.showModal }, on: { close: _vm.toggleModal } },
    [
      _vm._t("default", function () {
        return [
          _c(
            "h2",
            {
              staticClass:
                "mb-4 flex w-full items-center space-x-2 border-b pb-2 font-display text-2xl",
            },
            [
              _c("fv-icon", { attrs: { icon: "lifebuoy", size: "lg" } }),
              _c("span", [
                _vm._v("Submit follow-up on referral " + _vm._s(_vm.refId)),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4 mt-2 flex w-full flex-wrap" },
            [
              _vm.errorMessage
                ? _c(
                    "div",
                    { staticClass: "w-full", attrs: { id: "errors" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-4 flex rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800",
                          attrs: { role: "alert" },
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Info"),
                          ]),
                          _c("div", [
                            _c("p", [_vm._v(_vm._s(_vm.errorMessage))]),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c("base-label", { attrs: { for: "message" } }, [
                _vm._v(" Message "),
              ]),
              _c("textarea-input", {
                staticClass: "w-full",
                attrs: { id: "message", name: "message", placeholder: "" },
                model: {
                  value: _vm.textMessage,
                  callback: function ($$v) {
                    _vm.textMessage = $$v
                  },
                  expression: "textMessage",
                },
              }),
            ],
            1
          ),
        ]
      }),
      _c(
        "div",
        {
          staticClass: "flex w-full justify-end space-x-2 p-2",
          attrs: { slot: "buttons" },
          slot: "buttons",
        },
        [
          _c(
            "base-button",
            { attrs: { color: "cancel" }, on: { click: _vm.toggleModal } },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "base-button",
            {
              attrs: {
                loading: _vm.loading,
                color: "primary",
                disabled: _vm.loading,
              },
              on: { click: _vm.submit },
            },
            [_vm._v(" Send ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }