var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-1 flex flex-col space-y-3" },
    [
      _c(
        "div",
        { staticClass: "flex justify-between items-center mt-2 flex-wrap" },
        [
          _c(
            "form",
            {
              staticClass:
                "flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4 lg:items-center",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getClinics()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "inline-flex space-x-1 items-center" },
                [
                  _c("base-input", {
                    staticClass: " min-w-80",
                    attrs: {
                      id: "search",
                      name: "search",
                      placeholder: "Search",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                  _c(
                    "base-button",
                    { attrs: { color: "dark" } },
                    [_c("fv-icon", { attrs: { icon: "search" } })],
                    1
                  ),
                  _vm.filtersAreSet
                    ? _c(
                        "base-button",
                        {
                          attrs: { color: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.resetFilters()
                            },
                          },
                        },
                        [_c("fv-icon", { attrs: { icon: "close" } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "border-left" },
                [
                  _c(
                    "text-button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Show filters",
                          expression: "'Show filters'",
                        },
                      ],
                      staticClass: "border-l border-gray-700",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.showClinicFilterModal = true
                        },
                      },
                    },
                    [
                      _c("fv-icon", {
                        staticClass: "text-black mx-1",
                        attrs: { icon: "filter" },
                      }),
                      _vm._v(" Filter "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "inline-flex space-x-3 items-center" },
            [
              _c("text-button", { attrs: { to: { name: "clinic-map" } } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "inline-flex items-center space-x-2 text-primary-darker hover:text-primary-darkest",
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "text-sm",
                      attrs: { icon: "google-maps" },
                    }),
                    _c("span", { staticClass: "0" }, [_vm._v("Go to map")]),
                  ],
                  1
                ),
              ]),
              _c(
                "base-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showCreateClinicModal = true
                    },
                  },
                },
                [
                  _c("fv-icon", {
                    staticClass: "text-gray-600",
                    attrs: { icon: "add" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [_vm._v("Add a clinic")]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      Object.keys(_vm.filter).length !== 0
        ? _c(
            "div",
            { staticClass: "flex space-x-2" },
            _vm._l(_vm.filter, function (value, key, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass:
                    "bg-primary-darker text-white rounded-lg py-1 px-3 flex items-center",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(!value ? "missing " : "") +
                      " " +
                      _vm._s(key.toLowerCase().replace("set", "")) +
                      " " +
                      _vm._s(value ? "added " : "") +
                      " "
                  ),
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.removeFilter(key)
                        },
                      },
                    },
                    [
                      _c("fv-icon", {
                        staticClass: "ml-2",
                        attrs: { icon: "close", size: "xs" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex-1 relative" },
        [
          _c(
            "table-container",
            { attrs: { theads: _vm.theads } },
            [
              _vm._l(_vm.clinics, function (clinic) {
                return _c(
                  "tr",
                  {
                    key: clinic.id,
                    staticClass: "text-left text-sm hover:bg-gray-100",
                    class: { "bg-red-100": !clinic.active },
                  },
                  [
                    _c("table-data", [
                      _c(
                        "div",
                        { staticClass: "flex justify-between" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "font-semibold hover:text-primary-darkest",
                              attrs: {
                                to: {
                                  name: "clinic",
                                  params: { id: clinic.id },
                                },
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(clinic.name))])]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-gray-700 inline-flex space-x-1",
                            },
                            [
                              clinic.lat && clinic.lng
                                ? _c("fv-icon", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          "This clinic is visible on the map",
                                        expression:
                                          "'This clinic is visible on the map'",
                                      },
                                    ],
                                    attrs: { icon: "map-pin" },
                                  })
                                : _vm._e(),
                              clinic.animal_types.length
                                ? _c("fv-icon", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          "This clinic has at least 1 animal type added",
                                        expression:
                                          "'This clinic has at least 1 animal type added'",
                                      },
                                    ],
                                    attrs: { icon: "pet" },
                                  })
                                : _vm._e(),
                              clinic.referral_email || clinic.email
                                ? _c("fv-icon", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value:
                                          "This clinic has an email and/or referral email set",
                                        expression:
                                          "'This clinic has an email and/or referral email set'",
                                      },
                                    ],
                                    attrs: { icon: "email" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("table-data", [
                      clinic.clinic_type
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "rounded-full border bg-gray-100 py-1 px-2 text-xs",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    clinic.clinic_type &&
                                      clinic.clinic_type.name
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c("span", [_vm._v("-")]),
                    ]),
                    _c("table-data", [
                      _c("span", [_vm._v(_vm._s(clinic.email))]),
                    ]),
                    _c("table-data", [
                      _c("span", { staticClass: "text-gray-700" }, [
                        _vm._v(
                          " " +
                            _vm._s(clinic.city && clinic.city + ",") +
                            " " +
                            _vm._s(clinic.municipality) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("table-data"),
                  ],
                  1
                )
              }),
              _c(
                "div",
                {
                  staticClass: "flex w-full justify-between items-baseline",
                  attrs: { slot: "tableFooter" },
                  slot: "tableFooter",
                },
                [
                  _vm.uiState === "loading"
                    ? _c("spinner-overlay", {
                        attrs: {
                          loading: _vm.uiState === "loading",
                          size: "xxl",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "bg-gray-100 inline-flex space-x-4" },
                    [
                      _vm.clinicsList.prev_page_url ||
                      _vm.clinicsList.next_page_url
                        ? _c("pagination-buttons", {
                            attrs: {
                              "pagination-list": _vm.clinicsList,
                              loading: _vm.uiState === "loading",
                            },
                            on: {
                              previous: function ($event) {
                                return _vm.getClinics(
                                  _vm.clinicsList.prev_page_url
                                )
                              },
                              next: function ($event) {
                                return _vm.getClinics(
                                  _vm.clinicsList.next_page_url
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _c("v-select", {
                        staticClass: "v-input",
                        attrs: {
                          "append-to-body": true,
                          position: "top",
                          clearable: false,
                          searchable: false,
                          label: "name",
                          reduce: function (opt) {
                            return opt.id
                          },
                          options: _vm.paginationOptions,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.getClinics()
                          },
                        },
                        model: {
                          value: _vm.paginate,
                          callback: function ($$v) {
                            _vm.paginate = $$v
                          },
                          expression: "paginate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _vm._v(
                      "Viewing " +
                        _vm._s(_vm.clinics.length) +
                        " of " +
                        _vm._s(_vm.clinicsList.total) +
                        " clinics"
                    ),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.editClinic
        ? _c(
            "base-modal",
            {
              attrs: { show: _vm.showEditForm },
              on: { close: _vm.handleCloseEdit },
            },
            [
              _c("clinic-edit-card", {
                attrs: { "show-borders": false, clinic: _vm.editClinic },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showCreateClinicModal
        ? _c("clinic-create-modal", {
            attrs: { show: _vm.showCreateClinicModal },
            on: {
              close: function ($event) {
                _vm.showCreateClinicModal = false
              },
              "add-clinic": _vm.addClinic,
            },
          })
        : _vm._e(),
      _vm.showClinicFilterModal
        ? _c("clinic-filter-modal", {
            attrs: { show: _vm.showClinicFilterModal },
            on: {
              close: function ($event) {
                _vm.showClinicFilterModal = false
              },
              "handle-submit": function ($event) {
                return _vm.getClinics(null, $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }