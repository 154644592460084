<template>
  <base-modal
    :show="show"
    @close="$emit('close')"
  >
    <slot>
      <spinner-overlay
        color="light"
        :loading="loading"
      />
      <div v-if="!clinicCreated">
        <div>
          <h2 class="font-display text-2xl font-bold text-center mb-6 mt-2">
            Add a clinic
          </h2>
        </div>
     
        <ValidationObserver
          ref="observer"
          tag="form"
          class="space-y-8 px-4 mb-4"
          @submit.prevent=""
        >
          <div>
            <title-row class="block  font-semibold text-lg mb-4">
              Name and address
            </title-row>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="name"
                tag="div"
                class="w-full"
              >
                <base-label>
                  Name
                  <span class="text-gray-700">*</span>
                </base-label>
                <base-input
                  id="name"
                  v-model="form.name"
                  name="name"
                  placeholder="Name"
                  class="mt-1"
                />
                <transition name="fade">
                  <div
                    v-if="errors.length"
                    class="text-red-800 mt-1 font-semibold text-sm"
                  >
                    {{ errors[0] }}
                  </div>
                </transition>
              </ValidationProvider> 

              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="address"
                tag="div"
                class="w-full"
              >
                <base-label>
                  Address
                  <span class="text-gray-700">*</span>
                </base-label>
                <base-input
                  id="address"
                  v-model="form.adress"
                  name="address"
                  placeholder="Address"
                  class="mt-1"
                />
                <transition name="fade">
                  <div
                    v-if="errors.length"
                    class="text-red-800 mt-1 font-semibold text-sm"
                  >
                    {{ errors[0] }}
                  </div>
                </transition>
              </ValidationProvider> 

              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="postcode"
                tag="div"
                class="w-full"
              >
                <base-label>
                  Postcode
                  <span class="text-gray-700">*</span>
                </base-label>
                <base-input
                  id="postcode"
                  v-model="form.postcode"
                  name="postcode"
                  placeholder="Postcode"
                  class="mt-1"
                />
                <transition name="fade">
                  <div
                    v-if="errors.length"
                    class="text-red-800 mt-1 font-semibold text-sm"
                  >
                    {{ errors[0] }}
                  </div>
                </transition>
              </ValidationProvider> 
 
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="city"
                tag="div"
                class="w-full md:1/2"
              >
                <base-label>
                  City
                  <span class="text-gray-700">*</span>
                </base-label>
                <base-input
                  id="city"
                  v-model="form.city"
                  name="city"
                  placeholder="City"
                  class="mt-1"
                />
                <transition name="fade">
                  <div
                    v-if="errors.length"
                    class="text-red-800 mt-1 font-semibold text-sm"
                  >
                    {{ errors[0] }}
                  </div>
                </transition>
              </ValidationProvider>
  

              <ValidationProvider
                name="municipality"
                tag="div"
              >
                <base-label>
                  County
                </base-label>
                <base-input
                  id="municipality"
                  v-model="form.municipality"
                  name="municipality"
                  placeholder="County"
                  class="mt-1"
                />
              </ValidationProvider>

              <ValidationProvider
                v-if="platform !== 'sv'"
                v-slot="{ errors }"
                rules="required"
                name="country"
                tag="div"
                class="w-full"
              >
                <base-label>
                  Country
                  <span class="text-gray-700">*</span>
                </base-label>
                <custom-select
                  id="countryId"
                  name="countryId"
                  size="sm"
                  label-size="sm"
                  label-attr="name"
                  value-attr="id"
                  :searchable="true"
                  :options="filteredCountries"
                  :value="form.country_id"
                  class="mt-1"
                  @change="form.country_id = $event"
                /> 
                <transition name="fade">
                  <div
                    v-if="errors.length"
                    class="text-red-800 mt-1 font-semibold text-sm"
                  >
                    {{ errors[0] }}
                  </div>
                </transition>
              </ValidationProvider>
            </div>
          </div>

          <div>
            <title-row class="block  font-semibold text-lg mb-4">
              Clinic Details
            </title-row>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="clinic type"
                tag="div"
                class="w-full"
              >
                <base-label>
                  Clinic Type
                  <span class="text-gray-700">*</span>
                </base-label>
                <custom-select
                  id="clinic type"
                  name="clinic type"
                  size="sm"
                  label-size="sm"
                  label-attr="name"
                  value-attr="id"
                  :searchable="true"
                  :options="filteredClinicTypes"
                  :value="form.clinic_type_id"
                  class="mt-1"
                  @change="form.clinic_type_id = $event"
                /> 
                <transition name="fade">
                  <div
                    v-if="errors.length"
                    class="text-red-800 mt-1 font-semibold text-sm"
                  >
                    {{ errors[0] }}
                  </div>
                </transition>
              </ValidationProvider>

              <ValidationProvider
                name="status"
                tag="div"
                class="w-full"
              >
                <base-label>
                  Status
                  <span class="text-gray-700">*</span>
                </base-label>
                <div class="my-2 flex items-center max-h-full">
                  <label
                    class="text-sm mr-2"
                    :class="{'text-gray-500' : form.active}"
                    for="sign"
                  >
                    Inactive
                  </label>
                  <div class="form-switch max-h-full">
                    <input
                      id="sign"
                      v-model="form.active"
                      type="checkbox"
                      name="sign"
                      class="form-switch-checkbox"
                    >
                    <label
                      class="form-switch-label display-none"
                      for="sign"
                    />
                  </div>
                  <label
                    class="text-sm"
                    for="sign"
                    :class="{'text-gray-500' : !form.active}"
                  >
                    Active
                  </label>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div>
            <title-row class="block  font-semibold text-lg mb-4">
              Contact Details
            </title-row>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="{required: false, email: true}"
                name="email"
                tag="div"
              >
                <base-label>
                  Email
                </base-label>
                <base-input
                  id="email"
                  v-model="form.email"
                  name="email"
                  placeholder="Email"
                  class="mt-1"
                />
                <transition name="fade">
                  <div
                    v-if="errors.length"
                    class="text-red-800 mt-1 font-semibold text-sm"
                  >
                    {{ errors[0] }}
                  </div>
                </transition>
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                :rules="{required: true, email: true}"
                name="referral email"
                tag="div"
              >
                <base-label>
                  Referral Email
                  <span class="text-gray-700">*</span>
                </base-label>
                <base-input
                  id="referral email"
                  v-model="form.referral_email"
                  name="referral email"
                  placeholder="Referral Email"
                  class="mt-1"
                />
                <transition name="fade">
                  <div
                    v-if="errors.length"
                    class="text-red-800 mt-1 font-semibold text-sm"
                  >
                    {{ errors[0] }}
                  </div>
                </transition>
              </ValidationProvider>

              <ValidationProvider
                name="phone"
                tag="div"
              >
                <base-label>
                  Phone
                </base-label>
                <base-input
                  id="phone"
                  v-model="form.phone"
                  name="phone"
                  placeholder="Phone"
                  class="mt-1"
                />
              </ValidationProvider>

              <ValidationProvider
                name="homepage"
                tag="div"
              >
                <base-label>
                  Website
                </base-label>
                <base-input
                  id="homepage"
                  v-model="form.homepage"
                  name="homepage"
                  placeholder="Website"
                  class="mt-1"
                />
              </ValidationProvider>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <div
        v-else
        class="flex flex-col space-y-8"
      >
        <div>
          <h2 class="text-2xl font-bold text-center mb-6 mt-2">
            {{ clinic.name }}
          </h2>
 
          <div
            class="flex bg-azure-25 text-navy px-4 py-3 rounded-lg mt-1"
          >
            <font-awesome-icon
              class="text-gray-600"
              icon="info-circle"
            />
            <p class="ml-2">
              {{ !lng && !lat ? 'Clinic successfully added. Set location details below.' : 'Location saved!' }}
            </p>
          </div>
        </div>
        <div class="flex flex-col space-y-4">
          <h4 class="block  font-semibold text-lg">
            Location
          </h4>

          <desc-list>
            <desc-item>
              <desc-title for="lat">
                {{ $t('latitude') }}
              </desc-title>
              <desc-data>
                <p id="lat">
                  {{ lat }}
                </p>
              </desc-data>
            </desc-item>
            <desc-item>
              <desc-title for="lng">
                {{ $t('longitude') }}
              </desc-title>
              <desc-data>
                <p id="lng">
                  {{ lng }}
                </p>
              </desc-data>
            </desc-item>
          </desc-list>
          <GeocodeAdress
            :clinic-id="clinic.id"
            :address="clinic.adress"
            @coordinates-persisted="setCoordinates($event)"
          />
        </div>
      </div>
    </slot>
 
    <div
   
      slot="buttons"
      class="p-2 w-full flex space-x-2 justify-end"
    >
      <base-button
      
        color="cancel"
        @click="$emit('close', false)"
      >
        {{ clinicCreated ? 'Close' : 'Cancel' }}
      </base-button>
      <base-button
        v-if="!clinicCreated"
        :loading="loading"
        color="primary"
        :disabled="loading"
        @click="submit"
      >
        Save
      </base-button>
    </div>
  </base-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GeocodeAdress from './geocoder/GeocodeAdress.vue';

export default {
  components: {
    GeocodeAdress,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      isValid: false,
      clinicCreated: false,
      clinic: {},
      lat: '',
      lng: '',
      form: {
        name: '',
        adress: '',
        postcode: '',
        city: '',
        municipality: '',
        email: '',
        referral_email: '',
        phone: '',
        homepage: '',
        clinic_type_id: null,
        country_id: null,
        active: true,
      },
    };
  },
  computed: {
    ...mapState('admin', ['countryId', 'platform']),
    ...mapState('clinicStore', ['clinicTypes', 'countries']),
    filteredCountries() {
      return [
        {
          id: null,
          name: 'Country',
        },
        ...this.countries,
      ];
    },
    filteredClinicTypes() {
      return [
        {
          id: null,
          name: 'Clinic Type',
        },
        ...this.clinicTypes,
      ];
    },
  },

  created() {},
  mounted() {
    this.form.country_id = this.countryId;
    this.fetchClinicTypes({ country_id: this.countryId });
    this.fetchCountries();
  },
  methods: {
    ...mapActions('clinicStore', [
      'fetchClinicTypes',
      'fetchCountries',
      'storeClinic',
    ]),

    async submit() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;
      this.loading = true;

      const payload = {
        ...this.form,
      };

      await this.storeClinic(payload)
        .then(res => {
          this.$notify({
            group: 'primary',
            title: 'Success!',
            text: 'Clinic created successfully!',
          });
          this.clinic = res.data.clinic;
          this.$emit('add-clinic', this.clinic.id);
          this.clinicCreated = true;
          this.resetForm();
        })
        .catch(e => {
          this.error = e;

          this.$notify({
            group: 'error',
            title: 'Something went wrong',
            text: this.error.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      this.form = {
        name: '',
        adress: '',
        postcode: '',
        city: '',
        municipality: '',
        email: '',
        referral_email: '',
        phone: '',
        homepage: '',
        clinic_type_id: null,
        active: true,
      };
    },
    setCoordinates(payload) {
      this.lat = payload.lat;
      this.lng = payload.lng;
    },
  },
};
</script>
