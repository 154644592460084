var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c(
        "h4",
        {
          staticClass:
            "mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker",
        },
        [_vm._v(" " + _vm._s(_vm.$t("opening_hours")) + " ")]
      ),
      _c(
        "ValidationObserver",
        {
          ref: "observer",
          attrs: { tag: "form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "form",
            {
              staticClass: "w-full",
              attrs: { action: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("table", { staticClass: "min-w-full" }, [
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.openingHoursCopy, function (hours, index) {
                      return _c(
                        "tr",
                        { key: hours.weekday },
                        [
                          index === 0
                            ? _c(
                                "div",
                                { staticClass: "mb-2 flex text-center" },
                                [
                                  _c("td", { staticClass: "w-1/6" }),
                                  _c("td", { staticClass: "w-1/3" }, [
                                    _c(
                                      "label",
                                      { staticClass: "text-gray-600" },
                                      [
                                        _c("small", [
                                          _vm._v(_vm._s(_vm.$t("opens"))),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "w-1/3" }, [
                                    _c(
                                      "label",
                                      { staticClass: "text-gray-600" },
                                      [
                                        _c("small", [
                                          _vm._v(_vm._s(_vm.$t("closes"))),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("td", { staticClass: "w-1/5" }, [
                                    _c(
                                      "label",
                                      { staticClass: "text-gray-600" },
                                      [
                                        _c("small", [
                                          _vm._v(_vm._s(_vm.$t("closed"))),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c("ClinicOpeningHoursRow", {
                            attrs: {
                              "opening-hours": hours,
                              "hour-options": _vm.hourOptions,
                              "day-name": _vm.dayOptions[hours.weekday].key,
                            },
                            on: { "value-changed": _vm.changeHours },
                          }),
                        ],
                        1
                      )
                    }),
                    _c(
                      "tr",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "base-button",
                          {
                            staticClass: "mt-3",
                            attrs: {
                              size: "lg",
                              color: "primary",
                              disabled: _vm.loading,
                              type: "submit",
                              loading: _vm.loading,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.isSaved
                                    ? _vm.$t("save_opening_hours")
                                    : _vm.$t("opening_hours_saved")
                                ) +
                                " "
                            ),
                            _vm.isSaved
                              ? _c("fv-icon", {
                                  staticClass: "ml-2",
                                  attrs: { icon: "check-mark" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("tr", [
                      _vm.displayError
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mb-1 mt-4 flex items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900",
                            },
                            [
                              _c(
                                "span",
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "exclamation-circle" },
                                  }),
                                ],
                                1
                              ),
                              _c("span", [_vm._v(_vm._s(_vm.errorMessage))]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "tr",
                      [
                        _c("dt", { staticClass: "mt-4" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("other_opening_hours")) + " "
                          ),
                        ]),
                        _c("ClinicDataEditor", {
                          attrs: {
                            "html-element": "textarea",
                            clinic: _vm.clinic,
                            property: "open",
                            placeholder: _vm.$t(
                              "other_opening_hours_placeholder"
                            ),
                            required: false,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }