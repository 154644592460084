<template>
  <div class="relative flex flex-1">
    <card
      v-if="clinic || error"
      class="mx-auto flex h-full min-h-full w-full lg:w-2/3"
    >
      <p>{{ error }}</p>
      <div
        v-if="error || uiState === 'error'"
        class="mb-3 flex items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900"
      >
        <span><fv-icon icon="warning" /></span>
        <span>{{ error }}</span>
      </div>

      <div v-if="clinic" class="w-full">
        <div class="flex flex-col space-y-4 p-4">
          <div class="flex flex-col space-y-1 border-gray-300 pb-4">
            <div
              class="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between"
            >
              <p class="font-display text-3xl font-black">
                {{ clinic.name }}
              </p>

              <router-link
                :to="{ name: 'clinics' }"
                class="inline-flex items-center space-x-1 text-primary-darkest"
              >
                <fv-icon icon="chevron-left" /><span>Back</span>
              </router-link>
            </div>
            <p class="text-gray-700">
              {{ clinic.clinic_type && clinic.clinic_type.name }}
            </p>
          </div>

          <div class="flex w-full justify-between border-b">
            <div class="flex space-x-2 text-sm lg:space-x-6 lg:text-base">
              <button
                class="focus:outline-none border-b-2 border-transparent pb-2 hover:text-primary-darkest"
                :class="{ 'border-primary': view === 'information' }"
                @click="view = 'information'"
              >
                Information
              </button>
              <button
                class="focus:outline-none border-b-2 border-transparent pb-2 hover:text-primary-darkest"
                :class="{ 'border-primary': view === 'referrals' }"
                @click="view = 'referrals'"
              >
                Referrals
                <span class="text-sm">({{ clinic.referrals.length }})</span>
              </button>
              <button
                class="focus:outline-none border-b-2 border-transparent pb-2 hover:text-primary-darkest"
                :class="{ 'border-primary': view === 'rating' }"
                @click="view = 'rating'"
              >
                Rating
              </button>
            </div>
            <div class="flex space-x-1 pb-2">
              <base-button
                v-if="$can('manage', 'All')"
                @click="toggleEdit(clinic)"
              >
                <fv-icon icon="edit-alt" />
              </base-button>
            </div>
          </div>

          <div v-show="view === 'information'" class="flex flex-col space-y-6">
            <div class="flex justify-between md:w-full lg:w-2/3">
              <div class="flex flex-col">
                <base-label> Address: </base-label>
                <div class="flex flex-col space-y-1 text-gray-700">
                  <p>{{ clinic.adress }}</p>
                  <p>
                    <span v-if="clinic.postcode">{{ clinic.postcode }} </span>
                    <span v-if="clinic.city">{{ clinic.city }}</span>
                  </p>

                  <p v-if="clinic.municipality">
                    {{ clinic.municipality }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col">
                <base-label> Coordinates: </base-label>
                <div class="flex flex-col space-y-1 text-gray-700">
                  <p>Latitude: {{ clinic.lat }}</p>
                  <p>Longitude: {{ clinic.lng }}</p>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <base-label> Active: </base-label>
              <p
                v-if="clinic.active"
                class="flex flex-col space-y-1 text-gray-700"
              >
                Clinic is visible on map
              </p>
              <p
                v-if="!clinic.active"
                class="flex flex-col space-y-1 text-gray-700"
              >
                Clinic is hidden on map
              </p>
            </div>
            <div class="flex flex-col">
              <base-label> Contact: </base-label>
              <div class="flex flex-col space-y-1 text-gray-700">
                <p>{{ clinic.email }}</p>
                <p>
                  {{
                    clinic.referral_email &&
                    `${clinic.referral_email} (referral email)`
                  }}
                </p>
                <p>{{ clinic.phone }} (phone)</p>
                <p v-if="clinic.fax">{{ clinic.fax }} (fax)</p>
                <p v-if="clinic.homepage">
                  <a
                    :href="clinic.homepage"
                    target="_blank"
                    class="text-blue-700"
                    >{{ clinic.homepage }}</a
                  >
                </p>
              </div>
            </div>
            <div v-if="clinic.info" class="flex flex-col">
              <base-label> Information: </base-label>
              <div class="flex flex-col space-y-1 text-gray-700">
                <p class="max-w-md">
                  {{ clinic.info }}
                </p>
              </div>
            </div>
            <div class="flex flex-col">
              <base-label> Animal types: </base-label>
              <div class="flex flex-col space-y-1 text-gray-700">
                <span v-if="!clinic.animal_types.length"
                  >No animal types have been specified.</span
                >
                <span
                  v-for="animalType in clinic.animal_types"
                  :key="clinic.id + animalType.id"
                  class="text-gray-700"
                >
                  {{ animalType.name }}
                </span>
              </div>
            </div>
            <div class="flex w-full flex-col">
              <base-label> Opening hours: </base-label>
              <div class="flex flex-col space-y-1 text-gray-700">
                <p class="mb-2">
                  On call service:
                  <span
                    class="font-semibold"
                    v-text="clinic.on_call ? 'Yes' : 'No'"
                  />
                </p>
                <p
                  v-for="openingHour in clinic.opening_hours"
                  :key="clinic.id + openingHour.weekday"
                  class="text-gray-700"
                >
                  {{ getWeekdayName(openingHour) }}:
                  <span v-if="openingHour.closed">Closed</span>
                  <span v-else
                    >{{ openingHour.opens_at }} -
                    {{ openingHour.closes_at }}</span
                  >
                </p>
              </div>
            </div>
            <div class="flex flex-col">
              <base-label> Services: </base-label>
              <div class="flex flex-col space-y-1 text-gray-700">
                <span v-if="!clinic.services.length"
                  >No services have been specified.</span
                >
                <span
                  v-for="service in clinic.services"
                  :key="clinic.id + service.id"
                  class="text-gray-700"
                >
                  {{ service.name }}
                </span>
              </div>
            </div>
          </div>

          <div v-show="view === 'referrals'">
            <div v-if="clinic.referrals.length">
              <clinic-referral />
            </div>
            <p v-else class="rounded border bg-azure-25 px-5 py-4 text-navy">
              This clinic has no referrals.
            </p>
          </div>
          <div v-show="view === 'rating'">
            <div class="max-w-4xl">
              <clinic-rating-form
                v-if="view === 'rating'"
                :clinic-id="clinic.id"
                :clinic="clinic"
                @updated="fetchClinic"
              />
            </div>
          </div>
        </div>
      </div>
    </card>

    <base-modal
      v-if="editClinic"
      :show="showEditForm"
      @close="(editClinic = null), (showEditForm = false), fetchClinic()"
    >
      <clinic-edit-card :show-borders="false" :clinic="editClinic" />
    </base-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ClinicEditCard from '@/components/clinic-map/clinic/ClinicEditCard.vue';
import ClinicRatingForm from '@/components/clinic-map/clinic/ClinicRatingForm.vue';
import { ClinicsApi } from '@/api';
import ClinicReferral from '@/components/clinic-map/clinic/ClinicReferral';

export default {
  components: {
    ClinicReferral,
    ClinicEditCard,
    ClinicRatingForm,
  },
  data() {
    return {
      view: 'information',
      error: '',
      clinic: null,
      uiState: 'idle',
      weekdays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      showEditForm: false,
      editClinic: null,
    };
  },
  computed: {
    ...mapState(['platform']),
  },
  mounted() {
    this.fetchClinic();
  },
  methods: {
    async fetchClinic() {
      this.error = '';
      this.uiState = 'loading';
      try {
        const { data } = await ClinicsApi.getClinic(this.$route.params.id, {
          insuranceRatings: 1,
        });
        this.clinic = data;
      } catch (error) {
        this.error = error.message;
        this.uiState = 'error';
      }
      this.uiState = 'idle';
    },
    getWeekdayName(openingHour) {
      if (this.platform === 'sv') {
        return this.weekdays[openingHour.weekday_with_sunday_at_zero];
      }
      return this.weekdays[openingHour.weekday];
    },
    toggleEdit() {
      this.showEditForm = !this.showEditForm;
      this.editClinic = this.clinic;
    },
  },
};
</script>

<style lang="scss" scoped></style>
