var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("clinic-referral-search", {
        attrs: { loading: _vm.loading },
        on: { search: _vm.handleSearch },
      }),
      _vm._l(_vm.referrals, function (referral) {
        return _c(
          "div",
          { key: referral.id },
          [_c("clinic-referral-row", { attrs: { referral: referral } })],
          1
        )
      }),
      _c("pagination-buttons", {
        attrs: { "pagination-list": _vm.apiResponse, loading: _vm.loading },
        on: {
          previous: function ($event) {
            return _vm.fetchReferralsPaginate(_vm.previousPageUrl)
          },
          next: function ($event) {
            return _vm.fetchReferralsPaginate(_vm.nextPageUrl)
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }