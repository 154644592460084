<template>
  <div>
    <clinic-referral-search :loading="loading" @search="handleSearch" />
    <div v-for="referral in referrals" :key="referral.id">
      <clinic-referral-row :referral="referral" />
    </div>
    <pagination-buttons
      :pagination-list="apiResponse"
      :loading="loading"
      @previous="fetchReferralsPaginate(previousPageUrl)"
      @next="fetchReferralsPaginate(nextPageUrl)"
    />
  </div>
</template>

<script>
import PaginationButtons from '@/UI/PaginationButtons';
import { ClinicsApi } from '@/api';
import ClinicReferralRow from '@/components/clinic-map/clinic/ClinicReferralRow';
import ClinicReferralSearch from '@/components/clinic-map/clinic/ClinicReferralSearch';

export default {
  components: {
    ClinicReferralRow,
    ClinicReferralSearch,
    PaginationButtons,
  },
  data() {
    return {
      apiResponse: {
        data: [],
      },
      showModal: false,
      nextPageUrl: null,
      previousPageUrl: null,
      referrals: [],
      loading: false,
      uiState: 'hide',
      animalName: '',
      animalOwnerName: '',
      animalTypeId: '',
    };
  },
  mounted() {
    this.fetchReferrals();
  },
  methods: {
    displayApiError() {
      this.$notify({
        group: 'error',
        title: 'Api error',
        text: 'Something went wrong',
      });
      this.loading = false;
    },
    async fetchReferrals(page) {
      this.loading = true;
      page = page || 1;

      const payload = {
        page: page,
        animal_name: this.animalName,
        animal_owner_name: this.animalOwnerName,
        animal_type_id: this.animalTypeId,
      };

      try {
        const response = await ClinicsApi.fetchReferrals(
          this.$route.params.id,
          payload
        );
        this.handleApiResponse(response);
      } catch (error) {
        this.displayApiError();
      }
    },
    async fetchReferralsPaginate(url) {
      this.loading = true;
      try {
        const response = await ClinicsApi.fetchReferralsPaginate(url);
        this.handleApiResponse(response);
      } catch (error) {
        this.displayApiError();
      }
    },
    handleApiResponse(response) {
      this.apiResponse = response;
      this.referrals = response.data;
      this.previousPageUrl = response.prev_page_url;
      this.nextPageUrl = response.next_page_url;
      this.loading = false;
    },
    handleSearch(data) {
      this.animalName = data.animalName;
      this.animalOwnerName = data.animalOwnerName;
      this.animalTypeId = data.animalTypeId;

      this.fetchReferrals(null);
    },
  },
};
</script>

<style scoped></style>
