import { render, staticRenderFns } from "./ClinicAdminMap.vue?vue&type=template&id=4fbbfa33&scoped=true&"
import script from "./ClinicAdminMap.vue?vue&type=script&lang=js&"
export * from "./ClinicAdminMap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fbbfa33",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fbbfa33')) {
      api.createRecord('4fbbfa33', component.options)
    } else {
      api.reload('4fbbfa33', component.options)
    }
    module.hot.accept("./ClinicAdminMap.vue?vue&type=template&id=4fbbfa33&scoped=true&", function () {
      api.rerender('4fbbfa33', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/Clinics/ClinicAdminMap.vue"
export default component.exports