<template>
  <base-modal
    :show="show"
    @close="$emit('close')"
  >
    <form @submit.prevent="submit">
      <div>
        <h2 class="font-display text-2xl font-bold text-center mb-6">
          Filter
        </h2>
      </div>
      <div class="p-2 space-y-4">
        <clinic-filter-button
          v-for="(value, key, index) in modalFilter"
          :key="index"
          :title="setTitle(key)"
          :filter-value="value"
          @selected="setSelected(key, $event)"
        />
      </div>

      <div
        slot="buttons"
        class="py-2 w-full flex space-x-2 justify-end"
      >
        <base-button
          color="cancel"
          @click="$emit('close', false)"
        >
          Cancel
        </base-button>
        <base-button
          :loading="loading"
          color="dark"
          :disabled="loading"
          type="submit"
        >
          See clinics
        </base-button>
      </div>
    </form>
  </base-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ClinicFilterButton from '@/components/clinic-map/clinic/ClinicFilterButton';

export default {
  components: {
    ClinicFilterButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      modalFilter: {
        coordinatesSet: '',
        emailSet: '',
        animalTypesSet: '',
        openingHoursSet: '',
      },
      buttons: [
        {
          id: 1,
          label: 'All',
          value: '',
          state: 'active',
        },
        {
          id: 2,
          label: 'Missing',
          value: 0,
          state: 'inactive',
        },
        {
          id: 3,
          label: 'Added',
          value: 1,
          state: 'inactive',
        },
      ],
    };
  },
  computed: {
    ...mapState('admin', ['countryId']),
    ...mapState('clinicStore', ['filter']),
  },
  mounted() {
    this.modalFilter = { ...this.modalFilter, ...this.filter };
  },
  methods: {
    ...mapMutations('clinicStore', ['setFilter']),
    setButton(event) {
      console.log(event);
    },

    async submit() {
      // Filter out empty values
      const params = Object.fromEntries(
        Object.entries(this.modalFilter).filter(([_, v]) => v !== '') // eslint-disable-line 
      );

      // Set filters in state
      this.setFilter(params);

      this.$emit('handle-submit', params);
      this.$emit('close');
    },
    setTitle(title) {
      const result = title.replace(/([A-Z])/g, ' $1').toLowerCase();
      return (
        result.charAt(0).toUpperCase() + result.slice(1).replace('set', '')
      );
    },
    setSelected(key, event) {
      this.modalFilter[key] = event;
    },
  },
};
</script>
