var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "w-full",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.search.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "flex items-end justify-between space-x-3" }, [
        _c(
          "div",
          { staticClass: "flex flex-1 flex-col space-y-2" },
          [
            _c("base-label", { attrs: { for: "pet" } }, [_vm._v(" Pet ")]),
            _c("base-input", {
              ref: "petSearch",
              attrs: { id: "pet", placeholder: "Search for name or ID" },
              model: {
                value: _vm.animalName,
                callback: function ($$v) {
                  _vm.animalName = $$v
                },
                expression: "animalName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex flex-1 flex-col space-y-2" },
          [
            _c("base-label", { attrs: { for: "owner" } }, [_vm._v(" Owner ")]),
            _c("base-input", {
              attrs: { id: "owner", placeholder: "Search for name or ID" },
              model: {
                value: _vm.animalOwnerName,
                callback: function ($$v) {
                  _vm.animalOwnerName = $$v
                },
                expression: "animalOwnerName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-1" },
          [
            _c("base-select", {
              staticClass: "w-full text-sm",
              attrs: {
                "label-attribute": "name",
                "value-attribute": "id",
                options: _vm.animalTypes,
                placeholder: "Animal type",
                value: _vm.animalTypeId,
              },
              on: {
                change: function ($event) {
                  _vm.animalTypeId = $event
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inline-flex space-x-2" },
          [
            _c(
              "base-button",
              {
                attrs: {
                  loading: _vm.loading,
                  color: "primary",
                  type: "submit",
                },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "search" } }),
                _c("span", { staticClass: "ml-2 inline-flex" }, [
                  _vm._v("Search"),
                ]),
              ],
              1
            ),
            _vm.searched
              ? _c(
                  "base-button",
                  {
                    attrs: { color: "cancel", type: "button" },
                    on: { click: _vm.clearSearch },
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "h-4 w-4",
                      attrs: { icon: "close" },
                    }),
                    _c("span", { staticClass: "ml-2 inline-flex" }, [
                      _vm._v("Clear"),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }