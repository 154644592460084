var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: { show: _vm.show },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", [
            _c(
              "h2",
              {
                staticClass: "font-display text-2xl font-bold text-center mb-6",
              },
              [_vm._v(" Filter ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "p-2 space-y-4" },
            _vm._l(_vm.modalFilter, function (value, key, index) {
              return _c("clinic-filter-button", {
                key: index,
                attrs: { title: _vm.setTitle(key), "filter-value": value },
                on: {
                  selected: function ($event) {
                    return _vm.setSelected(key, $event)
                  },
                },
              })
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "py-2 w-full flex space-x-2 justify-end",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "cancel" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close", false)
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    color: "dark",
                    disabled: _vm.loading,
                    type: "submit",
                  },
                },
                [_vm._v(" See clinics ")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }