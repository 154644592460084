var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    staticClass: "form-control w-50",
    attrs: {
      options: _vm.options,
      label: "name",
      name: _vm.name,
      disabled: _vm.loading,
      reduce: function (opt) {
        return opt.id
      },
    },
    on: { input: _vm.submit },
    model: {
      value: _vm.clinicClone[_vm.property],
      callback: function ($$v) {
        _vm.$set(_vm.clinicClone, _vm.property, $$v)
      },
      expression: "clinicClone[property]",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }