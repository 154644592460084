<template>
  <div class="relative mx-auto pt-4">
    <ClinicMap
      :stand-alone="true"
      class="h-full w-full flex-shrink-0"
      :country="countryId"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ClinicMap from '@/components/clinic-map/ClinicMap';

export default {
  components: {
    ClinicMap,
  },
  computed: {
    ...mapState('admin', ['countryId']),
  },
};
</script>

<style lang="scss" scoped></style>
