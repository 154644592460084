var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "card mb-4 shadow-lg" }, [
        _c("div", { staticClass: "m-2 flex flex-row" }, [
          _c(
            "div",
            {
              class: [
                _vm.uiState === "show" ? "w-full grow" : "",
                "mt-2 min-w-60",
              ],
            },
            [
              _c("h4", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.referral.appointment.animal.name)),
              ]),
              _c("p", {}, [
                _vm._v(
                  _vm._s(_vm.referral.appointment.animal.animal_type.name)
                ),
              ]),
            ]
          ),
          _vm.uiState !== "show"
            ? _c(
                "div",
                { staticClass: "mt-2 w-full grow" },
                [
                  _c("base-label", [_vm._v(" Referred for: ")]),
                  _c(
                    "p",
                    { staticClass: "flex flex-col space-y-1 text-gray-700" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.trimText(_vm.referral.reason)) + " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "mt-2 flex-none" }, [
            _c("p", { staticClass: "font-semibold" }, [
              _vm._v(_vm._s(_vm.formatDate(_vm.referral.created_at))),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "cursor-pointer bg-beigeLighter",
            class: [_vm.uiState === "show" ? "border-b" : ""],
            on: {
              click: function ($event) {
                return _vm.toggleShowMore()
              },
            },
          },
          [
            _vm.uiState !== "show"
              ? _c("fv-icon", {
                  staticClass: "svg-fill inline-block text-blue-700",
                  attrs: { icon: "small-chevron-down", size: "xl" },
                })
              : _vm._e(),
            _vm.uiState === "show"
              ? _c("fv-icon", {
                  staticClass: "svg-fill inline-block text-blue-700",
                  attrs: { icon: "small-chevron-up", size: "xl" },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.uiState === "show"
          ? _c("div", {}, [
              _c("div", { staticClass: "m-2 mt-6" }, [
                _c(
                  "div",
                  { staticClass: "mb-2 flex flex-col" },
                  [
                    _c("base-label", [_vm._v(" Referred for: ")]),
                    _c(
                      "p",
                      { staticClass: "flex flex-col space-y-1 text-gray-700" },
                      [_vm._v(" " + _vm._s(_vm.referral.reason) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-2 flex flex-col" },
                  [
                    _c("base-label", [_vm._v(" Assessment: ")]),
                    _c(
                      "p",
                      { staticClass: "flex flex-col space-y-1 text-gray-700" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.referral.clinical_diagnosis) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-2 flex flex-col" },
                  [
                    _c("base-label", [_vm._v(" Further Contact: ")]),
                    _c(
                      "p",
                      { staticClass: "flex flex-col space-y-1 text-gray-700" },
                      [_vm._v(" " + _vm._s(_vm.referral.contact) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-2 flex flex-col" },
                  [
                    _c("base-label", [_vm._v(" Follow-up: ")]),
                    _c(
                      "p",
                      { staticClass: "flex flex-col space-y-1 text-gray-700" },
                      [_vm._v(" " + _vm._s(_vm.referral.ref_followup) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-4 rounded bg-blue-100 p-4 text-blue-700" },
                  [
                    _c(
                      "base-label",
                      { staticClass: "text-blue-700" },
                      [
                        _c("fv-icon", {
                          staticClass: "svg-fill inline-block text-blue-700",
                          attrs: { icon: "information", size: "lg" },
                        }),
                        _vm._v(" Referral instruction "),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "mt-4 flex flex-col space-y-1 font-medium",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.referral.referral_instruction) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.referral.followups.length
                  ? _c(
                      "div",
                      { staticClass: "mt-4" },
                      [
                        _c("base-label", [_vm._v("Referral follow-up")]),
                        _vm._l(_vm.referral.followups, function (followup) {
                          return _c("div", { key: followup.id }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-2 flex flex-col rounded border border-alt-lightest p-3",
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "flex flex-col space-y-1" },
                                  [_vm._v(_vm._s(followup.message))]
                                ),
                                _c("div", { staticClass: "mt-5" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "flex flex-col space-y-1 font-semibold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(followup.author.display_name) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mt-1 flex flex-col space-y-1 text-gray-700",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(followup.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-row justify-between bg-gray-100 p-2 pt-5 pb-5",
                },
                [
                  _c("div", {}, [
                    _c(
                      "p",
                      {},
                      [
                        _vm._v(" Meeting "),
                        _c(
                          "text-button",
                          {
                            attrs: {
                              to: {
                                name: "appointment",
                                params: { id: _vm.referral.appointment.id },
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-semibold text-blue-700 hover:text-blue-600",
                              },
                              [
                                _vm._v(
                                  " #" +
                                    _vm._s(_vm.referral.appointment.id) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {},
                      [
                        _vm._v(" Veterinarian "),
                        _c(
                          "text-button",
                          { attrs: { to: { name: "statistics" } } },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-semibold text-blue-700 hover:text-blue-600",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.referral.appointment.booking.user
                                        .display_name
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {},
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "w-full",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.showModal = true
                            },
                          },
                        },
                        [_vm._v("Submit follow-up")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _c("ClinicFollowUpModal", {
        attrs: { "show-modal": _vm.showModal, "ref-id": _vm.referral.id },
        on: { toggle: _vm.toggleModal, addFollowup: _vm.addFollowup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }