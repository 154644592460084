import { render, staticRenderFns } from "./ClinicOpeningHours.vue?vue&type=template&id=17756384&"
import script from "./ClinicOpeningHours.vue?vue&type=script&lang=js&"
export * from "./ClinicOpeningHours.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/firstvet/vetpanel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17756384')) {
      api.createRecord('17756384', component.options)
    } else {
      api.reload('17756384', component.options)
    }
    module.hot.accept("./ClinicOpeningHours.vue?vue&type=template&id=17756384&", function () {
      api.rerender('17756384', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/clinic-map/clinic/ClinicOpeningHours.vue"
export default component.exports