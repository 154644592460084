var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col space-y-4" }, [
    _c(
      "div",
      { staticClass: "flex flex-col space-y-2" },
      [
        _c(
          "h4",
          { staticClass: "block text-primary-darker font-semibold text-lg" },
          [_vm._v(" Rating ")]
        ),
        _c(
          "desc-list",
          [
            _c(
              "desc-item",
              [
                _c("desc-title", [_vm._v(" Price rating ")]),
                _c(
                  "desc-data",
                  [
                    _c("clinic-rating-slider", {
                      staticClass: "py-4",
                      attrs: {
                        value: _vm.clinic.rating_price,
                        "clinic-id": _vm.clinic.id,
                        property: "rating_price",
                        "default-value": 1,
                      },
                      on: {
                        updated: function ($event) {
                          return _vm.$emit("updated")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "desc-item",
              [
                _c("desc-title", [_vm._v(" Quality rating ")]),
                _c(
                  "desc-data",
                  [
                    _c("clinic-rating-slider", {
                      staticClass: "pt-4 pb-8",
                      attrs: {
                        value: _vm.clinic.rating_quality,
                        "clinic-id": _vm.clinic.id,
                        property: "rating_quality",
                        "default-value": 50,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._l(_vm.clinic.insurance_company_ratings, function (icRating) {
              return _c(
                "desc-item",
                { key: _vm.clinic.id + icRating.insurance_id },
                [
                  _c("desc-title", [
                    _vm._v(" " + _vm._s(icRating.name) + " rating "),
                  ]),
                  _c(
                    "desc-data",
                    [
                      _c("clinic-rating-slider", {
                        staticClass: "pt-4 pb-8",
                        attrs: {
                          value: icRating.rating,
                          "insurance-company-id": icRating.insurance_id,
                          "clinic-id": _vm.clinic.id,
                          property: "insurance_rating",
                          "default-value": 100,
                        },
                        on: {
                          updated: function ($event) {
                            return _vm.$emit("updated")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }