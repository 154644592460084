var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex items-center" }, [
    _c("td", { staticClass: "w-1/6" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t(_vm.dayName)))]),
    ]),
    _c(
      "td",
      { staticClass: "w-1/3" },
      [
        _c(
          "ValidationProvider",
          { attrs: { rules: _vm.timeSelectRule } },
          [
            _c(
              "v-select",
              {
                staticClass: "mb-2",
                attrs: {
                  transition: "none",
                  clearable: false,
                  options: _vm.hourOptions,
                  disabled: _vm.localHours.closed,
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("value-changed", _vm.localHours)
                  },
                },
                model: {
                  value: _vm.localHours.opens_at,
                  callback: function ($$v) {
                    _vm.$set(_vm.localHours, "opens_at", $$v)
                  },
                  expression: "localHours.opens_at",
                },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "no-options" }, slot: "no-options" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("selected_time_is_in_wrong_format")) +
                        " "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "td",
      { staticClass: "w-1/3 ml-2" },
      [
        _c(
          "ValidationProvider",
          { attrs: { rules: _vm.timeSelectRule } },
          [
            _c(
              "v-select",
              {
                staticClass: "mb-2 diagnosis-code-list",
                attrs: {
                  label: "label",
                  transition: "none",
                  clearable: false,
                  options: _vm.hourOptions,
                  disabled: _vm.localHours.closed,
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("value-changed", _vm.localHours)
                  },
                },
                model: {
                  value: _vm.localHours.closes_at,
                  callback: function ($$v) {
                    _vm.$set(_vm.localHours, "closes_at", $$v)
                  },
                  expression: "localHours.closes_at",
                },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "no-options" }, slot: "no-options" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("selected_time_is_in_wrong_format")) +
                        " "
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("td", { staticClass: "w-1/5 flex justify-center ml-1" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.localHours.closed,
            expression: "localHours.closed",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: _vm.localHours.closed,
          checked: Array.isArray(_vm.localHours.closed)
            ? _vm._i(_vm.localHours.closed, null) > -1
            : _vm.localHours.closed,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("value-changed", _vm.localHours)
          },
          change: function ($event) {
            var $$a = _vm.localHours.closed,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.localHours, "closed", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.localHours,
                    "closed",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.localHours, "closed", $$c)
            }
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }