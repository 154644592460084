<template>
  <div
    class="flex flex-col space-y-4"
    style="min-height: 20vh;"
  >
    <h4 class="block text-primary-darker text-lg font-semibold border-t border-gray-400 pt-3 mt-3">
      {{ $t('services') }}
    </h4>

    <desc-list>
      <desc-item class="border-none">
        <desc-title>
          {{ $t('add_specific_service') }}
        </desc-title>
        <desc-data class="mt-4">
          <form
            :ref="`servicesForm`"
            name="serviceList"
            @submit.prevent="updateServices"
          >
            <div class="">
              <base-label
                :for="_uid + '-animal_group'"
                class="mb-2 mt-3 block"
              >
                <small>{{ $t('animal_group') }}</small>
              </base-label>
              <v-select
                :id="_uid + '-animal_group'"
                v-model="selectedAnimalGroup"
                name="selectedAnimalGroup"
                class="form-control"
                label="name"
                :reduce="opt => opt.id"
                :options="clinicServiceAnimalGroups"
              />
            </div>
            <div class="">
              <base-label
                :for="_uid + '-category'"
                class="mb-2 mt-3 block"
              >
                <small>{{ $t('service_category') }}</small>
              </base-label>
              <v-select
                :id="_uid + '-category'"
                v-model="selectedCategory"
                name="serviceCategory"
                :options="clinicServiceCategories"
                label="name"
                :reduce="opt => opt.id"
              />
            </div>
            <div class="mt-2">
              <p
                v-if="error"
                class="text-red-600"
              >
                {{ error }}
              </p>
              <div
                v-for="service in availableServices"
                :key="service.id"
                class="my-1"
              >
                <template v-if="service.category_id == selectedCategory">
                  <label :for="service.key">
                    <input
                      v-model="service.attached"
                      type="checkbox"
                      :disabled="loading"
                      :name="service.key"
                      :checked="service.attached"
                      :value="service.id"
                      :true-value="true"
                      :false-value="false"
                    >
                    <span
                      class="ml-1"
                    ><small>{{ service.name }}</small></span>
                  </label>
                </template>
              </div>
              <div class="mt-4 text-right">
                <base-button
                  :disabled="!availableServices.length ||
                    !selectedCategory ||
                    !selectedAnimalGroup"
                  type="submit"
                  :loading="loading"
                  size="lg"
                  color="primary"
                >
                  {{ $t('save_services') }}
                </base-button>
              </div>
            </div>
          </form>
        </desc-data>
      </desc-item>

      <desc-item class="border-none">
        <desc-title>{{ $t('availability') }}</desc-title>
        <desc-data class="mt-1">
          <div class="form-group">
            <base-label
              for="emergencyCareOption"
              class="mb-2 mt-3 block"
            >
              <small>{{ $t('emergency_care_options') }}</small>
            </base-label>
            <ClinicDropdownEditor
              :clinic="clinic"
              :options="clinicEmergencyCareOptions"
              property="emergency_care_option"
              name="emergencyCareOption"
            />
          </div>
          <div class="form-group">
            <base-label
              for="onCallOption"
              class="mb-1 mt-3 block"
            >
              <small>{{ $t('on_call_options') }}</small>
            </base-label>
            <ClinicDropdownEditor
              :clinic="clinic"
              :options="clinicOnCallOptions"
              property="on_call"
              name="onCall"
            />
          </div>
          <div class="form-group">
            <base-label
              for="clinicStationaryCarePets"
              class="mb-2 mt-3 block"
            >
              <small>{{ $t('inpatient_care_small_animals') }}</small>
            </base-label>
            <ClinicDropdownEditor
              :clinic="clinic"
              :options="clinicStationaryCareOptions"
              property="stationary_care_pets"
              name="clinicStationaryCarePets"
            />
          </div>
          <div class="form-group">
            <base-label
              for="clinicStationaryCareHorses"
              class="mb-2 mt-3 block"
            >
              <small>{{ $t('inpatient_care_horses') }} </small>
            </base-label>
            <ClinicDropdownEditor
              :clinic="clinic"
              :options="clinicStationaryCareOptions"
              property="stationary_care_horses"
              name="clinicStationaryCareHorses"
            />
          </div>
        </desc-data>
      </desc-item>
    </desc-list>
    
    
    
    <!-- <div class="col-12 mt-1">
      <div class="row d-flex flex-wrap">
        <div class="col-6" v-if="petServices.length">
          <dl>
            <dt class="text-muted">
              <small>Smådjur/husdjur</small>
            </dt>

            <dd>
              <span
                class="badge badge-light badge-sm"
                v-for="(item, i) in petServices"
                :key="i"
                >{{ item.name }} - {{ item.category.name }}
              </span>
            </dd>
          </dl>
        </div>
        <div class="col-6" v-if="horseServices.length">
          <dl>
            <dt class="text-muted">
              <small>Häst</small>
            </dt>

            <dd>
              <span
                class="badge badge-light badge-sm"
                v-for="(item, i) in horseServices"
                :key="i"
                >{{ item.name }} - {{ item.category.name }}
              </span>
            </dd>
          </dl>
        </div>
        <div class="col-6" v-if="fieldHorseServices.length">
          <dl>
            <dt class="text-muted">
              <small>Häst (fält)</small>
            </dt>

            <dd>
              <span
                class="badge badge-light badge-sm"
                v-for="(item, i) in fieldHorseServices"
                :key="i"
                >{{ item.name }} - {{ item.category.name }}
              </span>
            </dd>
          </dl>
        </div>
        <div class="col-6" v-if="farmServices.length">
          <dl>
            <dt class="text-muted">
              <small>Lantbruksdjur</small>
            </dt>

            <dd>
              <span
                class="badge badge-light badge-sm"
                v-for="(item, i) in farmServices"
                :key="i"
                >{{ item.name }} - {{ item.category.name }}
              </span>
            </dd>
          </dl>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import ClinicDropdownEditor from '../form/ClinicDropdownEditor.vue';

export default {
  components: {
    ClinicDropdownEditor,
  },
  props: {
    clinic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: '',
      selectedCategory: null,
      selectedAnimalGroup: null,
      availableServices: [],
      services: cloneDeep(this.clinic.services),
      clinicOnCallOptions: [
        {
          id: 1,
          name: this.$t('yes'),
        },
        {
          id: 0,
          name: this.$t('no'),
        },
      ],
    };
  },
  computed: {
    ...mapState('clinicStore', [
      'clinicServiceCategories',
      'clinicServiceOptions',
      'clinicServiceAnimalGroups',
      'clinicEmergencyCareOptions',
      'clinicStationaryCareOptions',
    ]),
    allAvailableServices() {
      return this.clinicServiceOptions.map(service => {
        const clinicHasService = this.services.some(
          cService => cService.pivot.service === service.id
        );
        return {
          id: service.id,
          order: service.order,
          name: service.name,
          category_id: service.category_id,
          animal_group_id: service.animal_group_id,
          attached: !!clinicHasService,
        };
      });
    },
    selectedServices() {
      return this.allAvailableServices.filter(service => !!service.attached);
    },
    petServices() {
      const services = this.services.filter(
        ser => ser.pivot.animal_group_id === 1
      );
      return services;
    },
    horseServices() {
      const services = this.services.filter(
        ser => ser.pivot.animal_group_id === 2
      );
      return services;
    },
    fieldHorseServices() {
      const services = this.services.filter(
        ser => ser.pivot.animal_group_id === 3
      );
      return services;
    },
    farmServices() {
      const services = this.services.filter(
        ser => ser.pivot.animal_group_id === 4
      );
      return services;
    },
  },
  watch: {
    // availableServices: {
    //   deep: true,
    //   handler(val) {
    //     console.log(val);
    //   },
    // },
    selectedCategory: {
      handler() {
        this.setAvailableServices();
      },
    },
    selectedAnimalGroup: {
      handler() {
        this.setAvailableServices();
      },
    },
  },
  methods: {
    ...mapActions('clinicStore', ['syncServices']),
    async updateServices() {
      this.error = '';
      this.loading = true;
      try {
        console.log(this.selectedServices);
        const { data } = await this.syncServices({
          clinicId: this.clinic.id,
          services: this.selectedServices.map(service => service.id),
        });
        if (data.services) {
          this.services = data.services;
        }
        this.setAvailableServices();
        this.loading = false;
      } catch (error) {
        this.error = error;
        console.error(error);
        this.loading = false;
      }
    },
    setAvailableServices() {
      this.availableServices = this.allAvailableServices.filter(
        service => service.animal_group_id === this.selectedAnimalGroup
      );
      this.availableServices.sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
