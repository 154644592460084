var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.editing
        ? _c(
            "text-button",
            {
              class: { loading: "disabled" },
              on: {
                click: function ($event) {
                  return _vm.initEdit()
                },
              },
            },
            [
              _c(
                "base-label",
                {
                  staticClass: "text-break text-left cursor-pointer",
                  attrs: {
                    for: "inputField" + _vm.clinic.id + "-" + _vm.property,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      class: {
                        normalTextClassObj: !_vm.clinicClone[_vm.property],
                        flash: _vm.isFlashing,
                      },
                      attrs: { id: "label-span-" + _vm.property },
                      on: { animationend: _vm.resetFlashAnimation },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.clinicClone[_vm.property] || _vm.placeholder
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "form",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.submitEditClinic,
                  expression: "submitEditClinic",
                },
              ],
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitEditClinic.apply(null, arguments)
                },
              },
            },
            [
              _vm.htmlElement === "textarea"
                ? _c("textarea-input", {
                    staticClass: "form-control",
                    attrs: {
                      id: "inputField" + _vm.clinic.id + "-" + _vm.property,
                      rows: "3",
                      disabled: _vm.loading,
                    },
                    model: {
                      value: _vm.clinicClone[_vm.property],
                      callback: function ($$v) {
                        _vm.$set(_vm.clinicClone, _vm.property, $$v)
                      },
                      expression: "clinicClone[property]",
                    },
                  })
                : _c(_vm.htmlElement, {
                    tag: "component",
                    staticClass: "form-control",
                    attrs: {
                      id: "inputField" + _vm.clinic.id + "-" + _vm.property,
                      disabled: _vm.loading,
                      value: _vm.clinicClone[_vm.property] || "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setProperty($event)
                      },
                      blur: _vm.submitEditClinic,
                    },
                  }),
            ],
            1
          ),
      _vm.error
        ? _c("span", { staticClass: "text-red-600" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }