<template>
  <div>
    <text-button
      v-if="!editing"
      :class="{ loading: 'disabled' }"
      @click="initEdit()"
    >
      <base-label
        class="text-break text-left cursor-pointer"
        :for="`inputField${clinic.id}-${property}`"
      >
        <span
          :id="`label-span-${property}`"
          :class="{ normalTextClassObj : !clinicClone[property], flash : isFlashing }"
          @animationend="resetFlashAnimation"
        >
          {{ clinicClone[property] || placeholder }}
        </span>
      </base-label>
    </text-button>
    <form
      v-else
      v-click-outside="submitEditClinic"
      @submit.prevent="submitEditClinic"
    >
      <textarea-input
        v-if="htmlElement === 'textarea'"
        :id="`inputField${clinic.id}-${property}`"
        v-model="clinicClone[property]"
        class="form-control"
        rows="3"
        :disabled="loading"
      />
      <component
        :is="htmlElement"
        v-else
        :id="`inputField${clinic.id}-${property}`"
        :disabled="loading"
        class="form-control"
        :value="clinicClone[property] || ''"
        @input="setProperty($event)"
        @blur="submitEditClinic"
      />
    </form>
    <span
      v-if="error"
      class="text-red-600"
    >{{ error }}</span>
  </div>
</template>

<script>
// import vClickOutside from 'v-click-outside';
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  props: {
    property: {
      type: String,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    htmlElement: {
      type: String,
      default: 'base-input',
    },
    placeholder: {
      type: String,
      default() {
        return this.$i18n.tc('value_missing');
      },
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      clinicClone: cloneDeep(this.clinic),
      editing: false,
      loading: false,
      error: '',
      isFlashing: false,
      normalTextClassObj: {
        'text-gray-600': true,
        'font-normal': true,
        flash: false,
      },
    };
  },
  computed: {
    inputEl() {
      const inputEl = document.getElementById(
        `inputField${this.clinic.id}-${this.property}`
      );
      return inputEl;
    },

    textButtonEl() {
      console.log('COMPUTED TEXT BUTTON GETTER');
      const textButtonEl = document.getElementById(
        `label-span-${this.property}`
      );
      console.log(`label-span-${this.property}`);
      return textButtonEl;
    },
  },
  methods: {
    ...mapActions('clinicStore', ['updateClinic']),
    async submitEditClinic() {
      if (!this.clinic.id) {
        return;
      }

      this.error = '';
      const oldValue = this.clinic[this.property];
      let newValue = this.clinicClone[this.property] || '';
      if (newValue.trim() === '') newValue = '';

      if (newValue === oldValue) {
        this.editing = false;
        return;
      }
      if (this.required && !newValue.trim()) {
        this.error = this.$t('field_cannot_be_empty');
        this.clinicClone[this.property] = oldValue;
        return;
      }
      this.loading = true;
      try {
        const data = await this.updateClinic({
          id: this.clinic.id,
          [this.property]: newValue,
        });

        console.log('Saved clinic data', data);
        this.editing = false;
        this.loading = false;
        this.$emit('edited', this.clinicClone);
        this.$nextTick(this.flashInput);
      } catch (error) {
        this.error = error;
        this.clinicClone[this.property] = oldValue;
        this.editing = false;
        this.loading = false;
      }
    },
    flashInput() {
      this.isFlashing = true;
    },
    resetFlashAnimation() {
      this.isFlashing = false;
    },
    setProperty(value) {
      this.clinicClone[this.property] = value;
    },
    initEdit() {
      this.editing = true;
      this.$nextTick(this.focusOnInput);
    },
    focusOnInput() {
      if (this.clinic.id) {
        if (this.inputEl) {
          this.inputEl.focus();
        }
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.flash {
  animation: flash 1s;
}

@keyframes flash {
  50% {
    color: #00a7bd;
  }
  100% {
    color: #101c4e;
  }
}
</style>
