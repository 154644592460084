var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bg-white pb-8",
      class: { shadow: !_vm.clinic.id, "rounded border": _vm.showBorders },
    },
    [
      _vm.showMore
        ? _c(
            "div",
            {
              staticClass: "px-4",
              attrs: {
                id: "clinic_" + _vm.clinic.id,
                "data-parent": "#clinic-list",
              },
            },
            [
              _c(
                "p",
                {
                  staticClass: "pb-4 text-2xl font-black",
                  attrs: { id: "clinicName" + _vm.clinic.id },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.clinicName ? _vm.clinicName : "-") + " "
                  ),
                ]
              ),
              _vm.clinic.id
                ? _c(
                    "div",
                    {
                      staticClass: "flex items-center justify-between pt-6",
                      attrs: { id: "statusAndSizeDiv" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex w-2/3 items-center space-x-6" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-1" },
                            [
                              _c(
                                "base-label",
                                { attrs: { for: "clinicType" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("clinic_type")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "max-w-sm" },
                                [
                                  _c("ClinicDropdownEditor", {
                                    attrs: {
                                      clinic: _vm.clinic,
                                      options: _vm.clinicTypes,
                                      property: "clinic_type_id",
                                      name: "clinicType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "flex-1" },
                            [
                              _c("base-label", { attrs: { for: "status" } }, [
                                _vm._v(" " + _vm._s(_vm.$t("status")) + " "),
                              ]),
                              _c("ClinicBooleanCheckbox", {
                                attrs: {
                                  id: "status",
                                  clinic: _vm.clinic,
                                  property: "active",
                                },
                                on: { edited: _vm.updateIsActive },
                              }),
                              !_vm.isActive
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt-2 text-sm font-bold text-red-700",
                                    },
                                    [_vm._v(" * Clinic is hidden on map ")]
                                  )
                                : _vm._e(),
                              _vm.isActive
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt-2 text-sm font-bold text-green-700",
                                    },
                                    [_vm._v(" * Clinic is visible on map ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {},
                        [
                          _c("fv-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Press any value to edit",
                                expression: "'Press any value to edit'",
                              },
                            ],
                            staticClass: "text-xl",
                            attrs: { icon: "information" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "flex flex-col" }, [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker",
                      },
                      [_vm._v(" Name and address ")]
                    ),
                    _c(
                      "desc-list",
                      [
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [_vm._v(_vm._s(_vm.$t("name")))]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "name",
                                  },
                                  on: { edited: _vm.updateTitleData },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [
                              _vm._v(_vm._s(_vm.$t("address"))),
                            ]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "adress",
                                  },
                                  on: { edited: _vm.updateAddress },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [_vm._v(_vm._s(_vm.$t("city")))]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "city",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [_vm._v("Postcode")]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "postcode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [
                              _vm._v(_vm._s(_vm.$t("municipality"))),
                            ]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "municipality",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.countries && _vm.countries.length
                          ? [
                              _c(
                                "desc-item",
                                { staticClass: "border-none" },
                                [
                                  _c("desc-title", [
                                    _vm._v(_vm._s(_vm.$t("country"))),
                                  ]),
                                  _c(
                                    "desc-data",
                                    [
                                      _c("ClinicDropdownEditor", {
                                        attrs: {
                                          clinic: _vm.clinic,
                                          options: _vm.countries,
                                          property: "country_id",
                                          name: "country",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("contact_information")) + " "
                        ),
                      ]
                    ),
                    _c(
                      "desc-list",
                      [
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [_vm._v(_vm._s(_vm.$t("email")))]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [_vm._v(_vm._s(_vm.$t("phone")))]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [_vm._v("Fax")]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "fax",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [
                              _vm._v(_vm._s(_vm.$t("website"))),
                            ]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "homepage",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [
                              _vm._v(_vm._s(_vm.$t("email_referral"))),
                            ]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "referral_email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "desc-item",
                          { staticClass: "border-none" },
                          [
                            _c("desc-title", [
                              _vm._v(_vm._s(_vm.$t("phone_referral"))),
                            ]),
                            _c(
                              "desc-data",
                              [
                                _c("ClinicDataEditor", {
                                  attrs: {
                                    clinic: _vm.clinic,
                                    property: "referral_phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("div", { staticClass: "flex flex-col" }, [
                  _vm.clinic.id
                    ? _c(
                        "div",
                        { staticClass: "flex flex-col space-y-2" },
                        [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("size_general_info")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "desc-list",
                            [
                              _c(
                                "desc-item",
                                { staticClass: "border-none" },
                                [
                                  _c("desc-title", [
                                    _vm._v(_vm._s(_vm.$t("general_info"))),
                                  ]),
                                  _c(
                                    "desc-data",
                                    { staticClass: "w-75" },
                                    [
                                      _c("ClinicDataEditor", {
                                        attrs: {
                                          clinic: _vm.clinic,
                                          property: "info",
                                          "html-element": "textarea",
                                          required: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "desc-item",
                                { staticClass: "border-none" },
                                [
                                  _c("desc-title", [
                                    _vm._v(
                                      _vm._s(_vm.$t("referral_information"))
                                    ),
                                  ]),
                                  _c(
                                    "desc-data",
                                    { staticClass: "text-truncate" },
                                    [
                                      _c("ClinicDataEditor", {
                                        attrs: {
                                          "html-element": "textarea",
                                          clinic: _vm.clinic,
                                          property: "referral_info",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "desc-item",
                                { staticClass: "border-none" },
                                [
                                  _c("desc-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("information_horse_service")
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "desc-data",
                                    { staticClass: "w-75" },
                                    [
                                      _c("ClinicDataEditor", {
                                        attrs: {
                                          clinic: _vm.clinic,
                                          property: "info_horse",
                                          "html-element": "textarea",
                                          required: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "flex flex-col space-y-8" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col space-y-4" },
                      [
                        _c(
                          "h4",
                          {
                            staticClass:
                              "mt-3 block border-t border-gray-400 pt-3 text-lg font-semibold text-primary-darker",
                          },
                          [_vm._v(" Location ")]
                        ),
                        _c(
                          "desc-list",
                          [
                            _c(
                              "desc-item",
                              { staticClass: "border-none" },
                              [
                                _c("desc-title", { attrs: { for: "lat" } }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("latitude")) + " "
                                  ),
                                ]),
                                _c("desc-data", [
                                  _c("p", { attrs: { id: "lat" } }, [
                                    _vm._v(" " + _vm._s(_vm.lat) + " "),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "desc-item",
                              { staticClass: "border-none" },
                              [
                                _c("desc-title", { attrs: { for: "lng" } }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("longitude")) + " "
                                  ),
                                ]),
                                _c("desc-data", [
                                  _c("p", { attrs: { id: "lng" } }, [
                                    _vm._v(" " + _vm._s(_vm.lng) + " "),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("GeocodeAdress", {
                          attrs: {
                            "clinic-id": _vm.clinic.id,
                            address: _vm.address,
                          },
                          on: {
                            "coordinates-persisted": function ($event) {
                              return _vm.setCoordinates($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-col" },
                [
                  _vm.clinic.id
                    ? _c("ClinicAnimalType", {
                        attrs: {
                          "animal-type-list": _vm.clinic.animal_types,
                          "clinic-id": _vm.clinic.id,
                        },
                        on: {
                          edited: function ($event) {
                            return _vm.updateTypes($event)
                          },
                        },
                      })
                    : _vm._e(),
                  _c("ClinicServiceSelector", {
                    attrs: { clinic: _vm.clinic },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex space-x-12" },
                [
                  _c("ClinicOpeningHours", {
                    attrs: {
                      "clinic-id": _vm.clinic.id,
                      clinic: _vm.clinic,
                      "opening-hours": _vm.clinic.opening_hours,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }