<template>
  <div class="flex justify-between items-center">
    <base-label
      :for="title"
      class="font-semibold"
    >
      {{ title }}
    </base-label>
    <div>
      <button
        v-for="button in buttons"
        :key="button.id + 'coordinates'"
        :value="filterValue"
        :name="title"
        type="button"
        class="w-40 py-3 border-b-2"
        :class="filterValue === button.value ? 
          'border-primary-darker text-primary-darker' 
          : 'hover:border-primary-darker hover:text-primary-darker border-gray-300 text-gray-300'"
        @click="$emit('selected', button.value)"
      >
        {{ button.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    filterValue: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      buttons: [
        {
          id: 1,
          label: 'All',
          value: '',
          state: 'active',
        },
        {
          id: 2,
          label: 'Missing',
          value: 0,
          state: 'inactive',
        },
        {
          id: 3,
          label: 'Added',
          value: 1,
          state: 'inactive',
        },
      ],
    };
  },
};
</script>
